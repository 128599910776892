import { Button, IconButton, View24 } from '@mercell/mercell-ui';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Loader from '../../../../../components/Loader/Loader';
import { BidDelivery } from '../../../../../contracts/bidding';
import { Invitation } from '../../../../../contracts/dashboard';
import { Tender } from '../../../../../contracts/tender';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../../../providers/RouteProvider/RouteContext';
import { useTenderStatuses } from '../TenderStatus/TenderStatus';

export interface TenderActionsProps {
	tender: Tender;
	invitation: Invitation;
	bidDelivery: BidDelivery;
}

const TenderActions: React.FC<TenderActionsProps> = ({ tender, invitation, bidDelivery }) => {
	const { formatPath } = useContext(RouteContext);
	const { t } = useTranslation('tender_material');
	const status = useTenderStatuses(tender, invitation, bidDelivery);
	const { onStartDelivery, loading } = useContext(ApiContext);
	const { push } = useHistory();
	if (!status) return null;
	const {
		isDelivered,
		isPendingResult,
		isPendingInvitation,
		isInvitationDeclined,
		isOverDue,
		isBidWithdrawn,
	} = status;
	const invitationAccepted = !isPendingInvitation && !isInvitationDeclined;
	const bidDelivered = isDelivered || isPendingResult;
	const bidInProgress = bidDelivery !== null && bidDelivery.bidId !== '';

	const startDelivery = async () => {
		onStartDelivery(decodeURIComponent(tender.tenderId)).then(() => {
			push(formatPath(`/tender/${tender.tenderId}/bidding`));
		});
	};

	return (
		<Loader loading={loading}>
			<div>
				<div className="flex flex-wrap">
					{invitationAccepted && !isOverDue && !bidDelivered && !isBidWithdrawn && !bidInProgress && (
						<Button
							data-cy="deliverBid"
							scheme="primary"
							className="whitespace-no-wrap"
							onClick={startDelivery}
							data-loading
						>
							{t('start-bid-process')}
						</Button>
					)}
					{bidDelivered && (
						<IconButton
							data-cy="viewYourBidLink"
							onClick={() => push(formatPath(`/tender/${tender.tenderId}/bidding`))}
							scheme="horizontal"
							Icon={View24}
							data-loading
						>
							{t('view-bid')}
						</IconButton>
					)}
				</div>
			</div>
		</Loader>
	);
};

export default TenderActions;
