import './Sidebar.scss';

import { Catalog32, Information32, ListChecked32 } from '@mercell/mercell-ui';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import RouteContext from '../../providers/RouteProvider/RouteContext';

interface SidebarProps {
	tenderId: string;
}

const Sidebar = ({ tenderId }: SidebarProps) => {
	const { formatPath } = useContext(RouteContext);
	const linkClasses = 'flex mr-12 my-3 items-center bidmanager-sidebar-link';
	const location = useLocation();
	const { t } = useTranslation('common');

	const isTenderPage = location.pathname.includes('tender');
	const isBidProcessPage = location.pathname.includes('bidding');
	const isCommunicationPage = location.pathname.includes('communication');

	const overlayClasses = classnames('bidmanager-sidebar-overlay', {
		'bidmanager-sidebar-tender--selected': isTenderPage,
		'bidmanager-sidebar-bid--selected': isBidProcessPage,
		'bidmanager-sidebar-communication--selected': isCommunicationPage,
	});

	return (
		<section className="bidmanager-sidebar">
			<div className="flex flex-col w-full my-2 md:mt-24">
				<div className={overlayClasses} />
				<div className="flex flex-col align-end m-auto md:ml-auto">
					<Link to={formatPath(`/tender/${tenderId}`)} className={linkClasses}>
						<Catalog32 className="mr-4" />
						{t('tender-material-sidebar')}
					</Link>
					<Link to={formatPath(`/tender/${tenderId}/bidding`)} className={linkClasses}>
						<ListChecked32 className="mr-4" />
						{t('bid-process-sidebar')}
					</Link>
					<Link to={formatPath(`/tender/${tenderId}/communication`)} className={linkClasses}>
						<Information32 className="mr-4" />
						{t('communication-sidebar')}
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Sidebar;
