import './Login.scss';

import { Alert, Button, Input } from '@mercell/mercell-ui';
import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import { LoginForm } from '../../contracts/authentication';
import ApiContext from '../../providers/ApiProvider/ApiContext';
import RouteContext from '../../providers/RouteProvider/RouteContext';
import { useQuery } from '../../utils';

export const COOKIE_NAME = '.AspNetCore.Cookies';
export * from '../../components/AuthorizedRoute/AuthorizedRoute';
export * from './authorization-rules';
export * from '../../api';

const Login: React.FunctionComponent = () => {
	const [loginError, setLoginError] = useState<string>();
	const { register, handleSubmit, errors } = useForm<LoginForm>();
	const { onLogin, loading } = useContext(ApiContext);
	const { formatPath } = useContext(RouteContext);
	const { push } = useHistory();
	const query = useQuery();
	const { t } = useTranslation('login');

	const onSubmit = async (payload: LoginForm) => {
		onLogin(payload)
			.then(() => {
				const redirect = query.get('redirect');
				if (redirect) push(formatPath(redirect));
				else push(formatPath('/'));
			})
			.catch((error: AxiosError) => {
				if (error.response && error.response.status === 400) setLoginError('Wrong username or password.');
				else setLoginError(error.message);
			});
	};

	return (
		<Loader loading={loading}>
			<main className="sm:flex sm:justify-center bg-white w-full">
				<div className="flex justify-start bidmanager-login-outer-container">
					<div className="sm:w-md my-6 bidmanager-login-inner-container">
						<h1 data-loading className="my-4">
							{t('header')}
						</h1>
						<div>
							<h3 className="my-2" data-loading>
								{t('subheader')}
							</h3>

							{loginError && <Alert scheme="danger">{loginError}</Alert>}

							<form method="post" className="" onSubmit={handleSubmit(onSubmit)}>
								<Input
									type="text"
									data-cy="username"
									id="username"
									name="username"
									data-loading
									label={t('username-label')}
									ref={register({ required: true })}
									errorMessage={errors.username && t('username-error')}
								/>
								<Input
									type="password"
									data-cy="password"
									id="password"
									className="my-2"
									data-loading
									name="password"
									label={t('password-label')}
									ref={register({ required: true })}
									errorMessage={errors.password && t('password-error')}
								/>
								<Button
									type="submit"
									scheme="primary"
									data-cy="login"
									id="btnLogin"
									className="my-2"
									data-loading
									style={{ width: '150px' }}
								>
									{t('sign-in')}
								</Button>
							</form>
						</div>
					</div>
				</div>
			</main>
		</Loader>
	);
};

export default Login;
