import React, { useCallback } from 'react';

import RouteContext from './RouteContext';

interface RouteProviderProps {
	routePrefix: string;
}

const RouteProvider: React.FC<RouteProviderProps> = ({ routePrefix, children }) => {
	const formatPath = useCallback(
		(path: string) => {
			let newPath = path;

			// Handle root path with prefix
			if (newPath === '/' && routePrefix) {
				return `/${routePrefix}`;
			}

			// Handle absolute paths and remove beginning slash.
			if (newPath[0] === '/' && newPath.length > 1) newPath = path.substring(1);

			// Handle path if routeprefix and path exists
			if (routePrefix) {
				return `/${routePrefix}/${newPath}`;
			}

			// If routePrefix is empty and the path is / we return the root path. Otherwise
			// the result will be //
			if (newPath === '/') {
				return newPath;
			}
			// Return the correct absolute path if routePrefix doesn't exist
			return `/${newPath}`;
		},
		[routePrefix]
	);

	const value = {
		formatPath,
		routePrefix,
	};

	return <RouteContext.Provider value={value}>{children}</RouteContext.Provider>;
};

export default RouteProvider;
