import { Checkbox } from '@mercell/checkbox-react';
import { ActionMenu } from '@mercell/mercell-ui';
import { Action } from '@mercell/mercell-ui/dist/types/components/ActionMenu';
import cx from 'classnames';
import * as React from 'react';

export const getFileId = (file: File) => `file-${file.name.replace(/[^0-9a-z_-]/gi, '')}`;

export interface FileAction extends Omit<Action, 'handler'> {
	handler: (files: File[]) => void;
}
export interface ListFile extends File {
	isUploading?: boolean;
}

export interface FileListProps {
	id: string;
	files: ListFile[];
	actions?: FileAction[];
	actionMenuLabel?: string;
	selectAllLabel?: string;
	selectedFilesText?: (selected: File[], files: File[]) => string;
	getAllLabel?: (label: string) => string;
	selected: (File | ListFile)[];
	setSelected: React.Dispatch<React.SetStateAction<File[]>>;
}

const Spinner = () => (
	<>
		<img
			src="https://cdn.mercell.com/icons/Spinner_light_bkgd_24px.svg"
			className="hidden dark:block animate-spin"
			alt="spinner"
			width="18"
			height="18"
		/>
		<img
			src="https://cdn.mercell.com/icons/Spinner_dark_bkgd_24px.svg"
			className="dark:hidden animate-spin"
			alt="spinner"
			width="18"
			height="18"
		/>
	</>
);

export const FileList: React.FunctionComponent<FileListProps & JSX.IntrinsicElements['table']> = ({
	id,
	files,
	className,
	actions,
	actionMenuLabel,
	getAllLabel,
	selectAllLabel = 'Select all',
	selected,
	setSelected,
	...rest
}) => {
	const hasActions = React.useMemo(() => actions && actions.length > 0 && files.length > 0, [actions, files]);

	return (
		<table className={cx('w-full file-list', className)} id={id} {...rest}>
			{hasActions && (
				<thead>
					<tr className="h-16 flex justify-between items-end sm:justify-start">
						<th className="font-black text-left p-2">
							<Checkbox
								id={`${id}-select-all`}
								type="checkbox"
								name={selectAllLabel}
								className="current-color"
								checked={selected.length === files.length}
								onChange={(evt) => {
									const target = evt.target as HTMLInputElement;
									if (target.checked) setSelected(files);
									else setSelected([]);
								}}
							>
								{selectAllLabel}
							</Checkbox>
						</th>
						{hasActions && actions && selected.length > 0 && (
							<th className="pr-2">
								<ActionMenu
									id={`${id}-selected-actions`}
									label={actionMenuLabel}
									actions={actions?.map((action) => ({
										...action,
										label: getAllLabel ? getAllLabel(action.label) : action.label,
										className: 'flex-row rounded-none text-label-normal',
										handler: () => action.handler(selected),
										schemeType: 'horizontal',
									}))}
								/>
							</th>
						)}
					</tr>
				</thead>
			)}
			<tbody>
				{files.map((file) => {
					const { name, isUploading } = file;
					const fileId = getFileId(file);
					const isSelected = selected.some((selectedFile) => selectedFile.name === name);
					return (
						<tr
							key={fileId}
							className={cx(
								'flex items-center justify-between border-t border-grey-light py-0 px-2 hover:bg-grey-background',
								isSelected ? 'bg-grey-light' : 'bg-transparent'
							)}
						>
							<td className="flex items-center w-10/12">
								{isUploading && (
									<div className="py-3 flex items-center">
										<Spinner />
										<div className="pl-2 file-name" title={name}>
											{name}
										</div>
									</div>
								)}
								{!isUploading && hasActions && (
									<Checkbox
										type="checkbox"
										name={name}
										checked={isSelected}
										className="current-color w-full"
										id={fileId}
										onChange={(evt) => {
											const target = evt.target as HTMLInputElement;
											if (target.checked) setSelected([...selected, file]);
											else
												setSelected(
													selected.filter((selectedFile) => selectedFile.name !== name)
												);
										}}
									>
										<p className="file-name" title={name}>
											{name}
										</p>
									</Checkbox>
								)}
								{!isUploading && !hasActions && (
									<div className="py-3 file-name" title={name}>
										{name}
									</div>
								)}
							</td>
							{hasActions && actions && !isUploading && (
								<td>
									<ActionMenu
										id={`action-menu-${fileId}`}
										label={actionMenuLabel}
										actions={actions?.map((action) => ({
											...action,
											schemeType: 'vertical',
											handler: () => action.handler([file]),
										}))}
									/>
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default FileList;
