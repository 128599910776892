import { useLocation } from 'react-router-dom';

import { convertToUtcDateTime } from './dateTimeUtil';
import i18n from './i18n';

export function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function setApplicationLanguage(language: string) {
	i18n.changeLanguage(language);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toUrlParams(object: { [key: string]: any }): string {
	return Object.keys(object)
		.filter((key) => object[key] || object[key] === 0)
		.map((key) => `${key}=${encodeURIComponent(object[key])}`)
		.join('&');
}

export function scrollToTop() {
	window.scrollTo(0, 0);
}

export function checkDueDate(dueDate: Date) {
	const oneMinuteInMilliseconds = 60000;
	const diff = convertToUtcDateTime(dueDate).getTime() - convertToUtcDateTime(new Date()).getTime();
	const lessThanOneMin = Math.abs(diff) < oneMinuteInMilliseconds;

	return lessThanOneMin ? true : diff > 0;
}

// from https://reactjs.org/docs/concurrent-mode-suspense.html
export function wrapPromise<T>(promise: Promise<T>) {
	let status = 'pending';
	let result: T | Error;
	const suspender = promise.then(
		(r) => {
			status = 'success';
			result = r;
		},
		(e) => {
			status = 'error';
			result = e;
		}
	);
	return {
		read() {
			if (status === 'pending') {
				throw suspender;
			} else if (status === 'error') {
				throw result;
			}
			return result as T;
		},
	};
}

export default { useQuery };
