// State object keys
export const TENDER_KEY = 'tender';
export const INVITATION_KEY = 'invitation';
export const BID_KEY = 'bid';
export const COMMUNICATION_KEY = 'communication';
export const DELIVERY_KEY = 'bidDelivery';
export const RESOLVED_STATUS_KEY = 'resolved';
export const ONGOING_PROCESSES_KEY = 'ongoings';

// Reducer action types

export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_API_URL = 'SET_API_URL';

// env
export const DEV = 'dev';
export const QA = 'qa';
export const PROD = 'prod';
export const DEMO = 'demo';
export const TEST = 'test';

// api
export const DEFAULT_API_URL = '/';
