import { AxiosResponse } from 'axios';
import React, { useContext, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
	acceptInvitation,
	bidModifiedSinceLastSubmit,
	cancelEditBid,
	confirmContactInformation,
	declineInvitation,
	editBid,
	getBidByTenderId,
	getBidDelivery,
	getInvitation,
	getOngoingProcesses,
	getTender,
	getTenderCommunication,
	iWillNotDeliver,
	login,
	logout,
	postQuestion,
	resumeBid,
	startDelivery,
	submitBid,
	withdrawBid,
} from '../../api';
import { isAxios } from '../../components/ErrorComponent';
import { LoginForm } from '../../contracts/authentication';
import { Bid, BidDelivery } from '../../contracts/bidding';
import { CommunicationResponse, PostQuestionRequest } from '../../contracts/communication';
import { Invitation } from '../../contracts/dashboard';
import useSyncDocuments from '../../pages/BidManagement/Bidding/hooks/useSyncDocuments';
import { useAuthContext } from '../../pages/Login/AuthContext';
import RouteContext from '../RouteProvider/RouteContext';
import ApiContext from './ApiContext';
import apiReducer from './apiReducer';
import {
	BID_KEY,
	CLEAR_ERROR,
	CLEAR_LOADING,
	COMMUNICATION_KEY,
	DEFAULT_API_URL,
	DELIVERY_KEY,
	DEMO,
	DEV,
	ERROR,
	INVITATION_KEY,
	LOADING,
	ONGOING_PROCESSES_KEY,
	PROD,
	QA,
	RESOLVED_STATUS_KEY,
	SET_API_URL,
	SUCCESS,
	TENDER_KEY,
	TEST,
} from './constants';
import initialState from './initialState';
import { ApiContextValue, APIUrlObject, Payload } from './types';

const ApiProvider: React.FC = ({ children }) => {
	const { formatPath } = useContext(RouteContext);
	const [state, dispatch] = useReducer(apiReducer, initialState);
	const { syncUploadDocuments, syncRemoveDocuments } = useSyncDocuments();
	const { pathname } = useLocation();
	const { setLoggedIn, setLoggedOut } = useAuthContext();
	const history = useHistory();

	const fetchBidManagementData = async (tenderId: string) => {
		handleRequest(async () => {
			const [tender, invitation, bid, communication] = await Promise.all([
				getTender(tenderId).then((res) => res.data),
				getInvitation(tenderId).then((res) => res.data),
				getBidByTenderId(tenderId).then((res) => res.data),
				getTenderCommunication(tenderId).then((res) => res.data),
			]);

			const base = {
				[TENDER_KEY]: tender,
				[INVITATION_KEY]: invitation,
				[BID_KEY]: bid,
				[COMMUNICATION_KEY]: communication,
				[RESOLVED_STATUS_KEY]: true,
			};

			if (bid) {
				const response = await getBidDelivery(bid.bidId);
				const delivery = response.data;

				return { ...base, [DELIVERY_KEY]: delivery };
			}
			return base;
		});
	};

	const fetchInvitation = async (): Promise<{ invitation: Invitation }> => {
		const { tenderId } = state.invitation;
		const response = await getInvitation(tenderId);

		return { [INVITATION_KEY]: response.data };
	};

	const fetchTenderAndDeliveryStatus = async (): Promise<{ bid: Bid; bidDelivery: BidDelivery }> => {
		const { tenderId } = state.tender;

		const bid = await getBidByTenderId(tenderId).then((res) => res.data);
		const delivery = await getBidDelivery(bid.bidId).then((res) => res.data);

		return { [BID_KEY]: bid, [DELIVERY_KEY]: delivery };
	};

	const fetchQuestions = async (): Promise<{ communication: CommunicationResponse }> => {
		const { tenderId } = state.tender;

		const data = await getTenderCommunication(tenderId).then((res) => res.data);
		return { [COMMUNICATION_KEY]: data };
	};

	const onNewQuestion = async (request: PostQuestionRequest) =>
		handleRequest(async () => {
			await postNewQuestion(request);
			return fetchQuestions();
		});

	const onStartDelivery = async (tenderId: string) =>
		handleRequest(async () => {
			await startDelivery(tenderId);
			return fetchTenderAndDeliveryStatus();
		});

	const onDeclineDelivery = async (invitationId: string) =>
		handleRequest(async () => {
			await iWillNotDeliver(invitationId);
			return fetchInvitation();
		});

	const onDeclineInvitation = async (invitationId: string) =>
		handleRequest(async () => {
			await declineInvitation({ invitationId });
			return fetchInvitation();
		});

	const onAcceptInvitation = async (invitationId: string) =>
		handleRequest(async () => {
			await acceptInvitation({ invitationId });
			return fetchInvitation();
		});

	const onConfirmContactInformation = async (contactPerson: string, contactEmail: string, bidId: string) =>
		handleRequest(async () => updateContactInfo(contactPerson, contactEmail, bidId));

	const onUploadDocuments = async (files: File[], bid: Bid) =>
		handleRequest(
			async () =>
				/* We need to provide the oppertunity to sending requests in sequence since the server
					does not support concurrency for modifying the same aggregates at the moment.
					So when for exampling uploading multiple documents - this needs to be done in sequence.



				https://stackoverflow.com/questions/48957022/unexpected-await-inside-a-loop-no-await-in-loop
				https://eslint.org/docs/rules/no-await-in-loop#when-not-to-use-it
			*/
				// eslint-disable-next-line no-restricted-syntax

				updateDocuments(files, bid),
			false
		);

	const onRemoveDocuments = async (files: File[], bid: Bid) =>
		handleRequest(async () => removeDocuments(files, bid), false);

	const onSubmitBid = async (bid: Bid) =>
		handleRequest(async () => {
			const { bidId } = bid;
			await submitBid(bidId);

			const response = await getBidDelivery(bidId);
			const delivery = await response.data;
			return { [DELIVERY_KEY]: delivery };
		});

	const hasContactInfoChanged = (contactPerson: string, contactEmail: string, bid: Bid): boolean =>
		bid.contactInformation.contact.fullname !== contactPerson ||
		bid.contactInformation.contact.emailAddress !== contactEmail;

	const onSubmitEditedBid = async (contactPerson: string, contactEmail: string, bid: Bid) =>
		handleRequest(async () => {
			const updatedBid = hasContactInfoChanged(contactPerson, contactEmail, bid)
				? await updateContactInfo(contactPerson, contactEmail, bid.bidId).then((res) => res.bid)
				: bid;
			const { bidId } = bid;
			await submitBid(bidId);
			const delivery = await getBidDelivery(bidId).then((response) => response.data);
			return { [BID_KEY]: updatedBid, [DELIVERY_KEY]: delivery };
		});

	const onEditBid = async (bid: Bid) =>
		handleRequest(async () => {
			const { bidId } = bid;
			await editBid(bidId);
			const response = await getBidDelivery(bidId);
			const delivery = await response.data;
			return { [DELIVERY_KEY]: delivery };
		});

	const onCancelEditBid = async (bid: Bid) =>
		handleRequest(async () => {
			const { bidId } = bid;
			await cancelEditBid(bidId);
			const response = await getBidDelivery(bidId);
			const delivery = await response.data;
			return { [DELIVERY_KEY]: delivery };
		});

	const onLogin = (payload: LoginForm) =>
		handleRequest(async () => {
			const { data } = await login(payload);
			setLoggedIn({ username: data });
			return {};
		});

	const onLogout = () =>
		handleRequest(async () => {
			await logout();
			setLoggedOut();
			return {};
		});

	const onOngoingProcesses = () =>
		handleRequest(async () => {
			const { data } = await getOngoingProcesses();
			return { [ONGOING_PROCESSES_KEY]: data };
		});

	const onWithdrawBid = async (bidId: string) =>
		handleRequest(async () => {
			await withdrawBid(bidId);
			return fetchTenderAndDeliveryStatus();
		});

	const onResumeBid = async (bidId: string) =>
		handleRequest(async () => {
			await resumeBid(bidId);
			return fetchTenderAndDeliveryStatus();
		});

	const isBidModifiedSinceLastSubmit = async (bidId: string): Promise<boolean> => {
		const result = await bidModifiedSinceLastSubmit(bidId);
		return result.data;
	};

	const updateContactInfo = async (
		contactPerson: string,
		contactEmail: string,
		bidId: string
	): Promise<{ bid: Bid }> => {
		const { tenderId } = state.tender;
		const bodyFormData = new FormData();
		bodyFormData.append('contactPerson', contactPerson);
		bodyFormData.append('contactEmail', contactEmail);

		await confirmContactInformation(bodyFormData, bidId);
		const data = await getBidByTenderId(tenderId).then((res) => res.data);
		return { [BID_KEY]: data };
	};

	const postNewQuestion = async (
		sendDataRequest: PostQuestionRequest
	): Promise<{ communication: AxiosResponse<CommunicationResponse> }> => {
		const { tenderId } = state.tender;
		const data = await postQuestion(sendDataRequest, tenderId);
		return { [COMMUNICATION_KEY]: data };
	};

	const setAPIUrl = (env?: string): void => {
		const apiUrls: APIUrlObject = {
			[DEV]: 'http://localhost:5000',
			[QA]: 'http://bidmanager-website',
			[TEST]: 'https://uat.bidmanager.mercell.com',
			[DEMO]: 'https://demo.bidmanager.mercell.com',
			[PROD]: 'https://bidmanager.mercell.com',
		};

		if (env) {
			dispatch({
				type: SET_API_URL,
				payload: { apiUrl: apiUrls[env] || DEFAULT_API_URL },
			});
		}
	};

	const updateDocuments = async (files: File[], bid: Bid): Promise<{ bid: Bid; bidDelivery: BidDelivery }> => {
		await syncUploadDocuments(files, bid);
		return fetchTenderAndDeliveryStatus();
	};

	const removeDocuments = async (files: File[], bid: Bid): Promise<{ bid: Bid; bidDelivery: BidDelivery }> => {
		await syncRemoveDocuments(files, bid);
		return fetchTenderAndDeliveryStatus();
	};

	const clearError = (): void => {
		dispatch({ type: CLEAR_ERROR });
	};

	const clearLoading = (): void => {
		dispatch({ type: CLEAR_LOADING });
	};

	const handleRequest = async (callback: () => Promise<Payload>, useLoading = true) => {
		if (useLoading) {
			dispatch({ type: LOADING });
		}

		try {
			const payload = await callback();
			dispatch({ type: SUCCESS, payload });
		} catch (e) {
			dispatch({ type: ERROR, payload: { error: e } });
			if (isAxios(e) && e.response?.status === 401) {
				const generatedPath = formatPath(`login?redirect=${pathname}`);
				history.push(generatedPath);
				return;
			}
			// Throw an error here to be able to catch it in the components should we need it.
			// This means we need to add catch handlers to all functions that wants to do special
			// error handling for a specific HTTP response code
			throw e;
		}
	};

	const value: ApiContextValue = {
		...state,
		fetchInvitation,
		fetchBidManagementData,
		onStartDelivery,
		onAcceptInvitation,
		onDeclineInvitation,
		onConfirmContactInformation,
		onUploadDocuments,
		onRemoveDocuments,
		onSubmitBid,
		onSubmitEditedBid,
		onEditBid,
		onCancelEditBid,
		onNewQuestion,
		onDeclineDelivery,
		onLogin,
		onLogout,
		onOngoingProcesses,
		onWithdrawBid,
		onResumeBid,
		isBidModifiedSinceLastSubmit,
		clearError,
		clearLoading,
		setAPIUrl,
	};
	return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
