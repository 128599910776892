import uniqBy from 'lodash/uniqBy';

export const duplicatesBy = <T>(uniqes: T[], all: T[], key: keyof T) => {
	if (uniqes.length === all.length) return [];
	return uniqes.reduce((remaining, uniq) => {
		const position = remaining.findIndex((remainder) => remainder[key] === uniq[key]);
		if (position > -1) return remaining.filter((element, index) => index !== position);
		return remaining;
	}, all);
};

export function mergeFileLists(...fileLists: FileList[]) {
	const files = uniqBy(
		fileLists
			.map((fileList) => Array.from(fileList))
			.flat()
			.filter((file) => file instanceof File),
		'name'
	);

	const fileList = new DataTransfer();
	files.forEach((file: File) => {
		fileList.items.add(file);
	});
	return fileList.files;
}

export const createFileList = (files: File[]): FileList | undefined => {
	if (typeof DataTransfer === 'undefined') return undefined;
	const formFileList = new DataTransfer();

	if (files) {
		files.forEach((file) => {
			formFileList.items.add(file);
		});
	}

	return formFileList.files;
};
