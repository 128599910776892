import './OngoingProcessActions.scss';

import { Catalog24, Chat24, IconButton, ListChecked24, View24 } from '@mercell/mercell-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { OngoingProcess } from '../../../../contracts/dashboard';
import RouteContext from '../../../../providers/RouteProvider/RouteContext';
import * as selectors from '../../selectors';

export interface OngoingProcessActionsProps {
	ongoingProcess: OngoingProcess;
}

const OngoingProcessActions: React.FunctionComponent<OngoingProcessActionsProps> = (props) => {
	const { formatPath } = useContext(RouteContext);
	const { ongoingProcess } = props;

	const history = useHistory();
	const { t } = useTranslation('dashboard');
	const status = selectors.getOngoingProcessStatus(ongoingProcess);

	const canCommunicate = status.isBidInProgress || status.isBidWithDrawn || status.isBidSubmitted;
	const canViewBid = status.isBidSubmitted || status.isWaitingForEvaluation;
	const canEditBid = status.isBidInProgress || status.isBidWithDrawn;

	const tenderId = encodeURIComponent(ongoingProcess.invitation.tenderId);

	const gotoTender = () => {
		history.push(formatPath(`/tender/${tenderId}`));
	};

	const gotoCommunication = () => {
		history.push(formatPath(`/tender/${tenderId}/communication`));
	};

	const gotoViewBid = () => {
		const bidId = ongoingProcess.bidId ? encodeURIComponent(ongoingProcess.bidId) : '';

		if (canViewBid && bidId) {
			history.push(formatPath(`/tender/${tenderId}/bidding/${bidId}/summary-readonly`));
		} else if (status.isBidInProgress && bidId) {
			history.push(formatPath(`/tender/${tenderId}/bidding/${bidId}/upload`));
		} else if (status.isBidWithDrawn && bidId) {
			history.push(formatPath(`/tender/${tenderId}/bidding/${bidId}/withdrawn`));
		} else {
			history.push(formatPath(`/tender/${tenderId}/bidding`));
		}
	};

	return (
		<div className="ongoing-process-actions">
			<div className="flex flex-wrap">
				<IconButton data-cy="tender-material" scheme="horizontal" Icon={Catalog24} onClick={gotoTender}>
					{t('tender-material-action')}
				</IconButton>
				{canViewBid && (
					<IconButton scheme="horizontal" Icon={View24} data-cy="viewYourBid" onClick={gotoViewBid}>
						{t('view-your-bid-action')}
					</IconButton>
				)}
				{canEditBid && (
					<IconButton scheme="horizontal" Icon={ListChecked24} data-cy="bidDeliveryBid" onClick={gotoViewBid}>
						{t('bid-delivery-action')}
					</IconButton>
				)}
				{canCommunicate && (
					<IconButton scheme="horizontal" Icon={Chat24} data-cy="communication" onClick={gotoCommunication}>
						{t('questions-answers-action')}
					</IconButton>
				)}
			</div>
		</div>
	);
};

export default OngoingProcessActions;
