import * as React from 'react';

import { LoginForm } from '../../contracts/authentication';
import { Bid } from '../../contracts/bidding';
import { PostQuestionRequest } from '../../contracts/communication';
import { Invitation } from '../../contracts/dashboard';
import initialState from './initialState';
import { ApiContextValue } from './types';

const initalValues: ApiContextValue = {
	...initialState,
	fetchInvitation: (tenderId: string) => new Promise<{ invitation: Invitation }>((resolve) => resolve),
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	fetchBidManagementData: (tenderId: string) => {},
	onStartDelivery: (tenderId: string) => new Promise((resolve) => resolve()),
	onAcceptInvitation: (invitationId: string) => new Promise((resolve) => resolve()),
	onDeclineInvitation: (invitationId: string) => new Promise((resolve) => resolve()),
	onConfirmContactInformation: (contactPerson: string, contactEmail: string, bidId: string) =>
		new Promise((resolve) => resolve()),
	onUploadDocuments: (files: File[], bid: Bid) => new Promise((resolve) => resolve()),
	onRemoveDocuments: (files: File[], bid: Bid) => new Promise((resolve) => resolve()),
	onSubmitBid: (bid: Bid) => new Promise((resolve) => resolve()),
	onSubmitEditedBid: (contactPerson: string, contactEmail: string, bid: Bid) => new Promise((resolve) => resolve()),
	onEditBid: (bid: Bid) => new Promise((resolve) => resolve()),
	onCancelEditBid: (bid: Bid) => new Promise((resolve) => resolve()),
	onNewQuestion: (request: PostQuestionRequest) => new Promise((resolve) => resolve()),
	onDeclineDelivery: (invitationId: string) => new Promise((resolve) => resolve()),
	onOngoingProcesses: () => new Promise((resolve) => resolve()),
	onWithdrawBid: (bidId: string) => new Promise((resolve) => resolve()),
	onResumeBid: (bidId: string) => new Promise((resolve) => resolve()),
	isBidModifiedSinceLastSubmit: (bidId: string) => new Promise<boolean>((resolve) => resolve),
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	clearError: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	clearLoading: () => {},
	onLogin: (payload: LoginForm) => new Promise((resolve) => resolve()),
	onLogout: () => new Promise((resolve) => resolve()),
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setAPIUrl: () => {},
};

const ApiContext = React.createContext(initalValues);

export default ApiContext;
