import * as React from 'react';

const Modal: React.FC = ({ children }) => (
	<div
		id="modalLayer"
		className="__hidden fixed z-10 inset-0 flex justify-center items-center"
		style={{ zIndex: 1000, backgroundColor: 'rgba(100,100,100,0.5)' }}
	>
		<div
			id="modalFrame"
			className="bg-white rounded shadow content m-4 sm:m-8 px-4 py-6 sm:px-6 sm:py-8"
			role="dialog"
			aria-modal="true"
		>
			{children}
		</div>
	</div>
);

export default Modal;
