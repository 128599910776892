import { Alert, Button, Information24 } from '@mercell/mercell-ui';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BidProcessIcon } from '../../../../../assets/icons/bid-process.svg';
import Loader from '../../../../../components/Loader/Loader';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';

const IWillNotDeliver: React.FunctionComponent = () => {
	const { push } = useHistory();
	const { loading } = useContext(ApiContext);
	const { t } = useTranslation('bidding');

	return (
		<div className="flex">
			<Loader loading={loading}>
				<main className="space-y-4">
					<h1 data-loading>{t('bidding-header')}</h1>
					<Alert scheme="info" className="flex" data-loading>
						<Information24 className="mr-2" />
						<p>{t('will-not-bid-alert')}</p>
					</Alert>
					<div className="sm:flex" data-loading>
						<Button
							scheme="tertiary"
							onClick={async () => {
								push('/dashboard');
							}}
						>
							{t('to-dashboard')}
						</Button>
					</div>
				</main>
				<aside className="hidden sm:block justify-center content-spacing">
					<BidProcessIcon data-loading />
				</aside>
			</Loader>
		</div>
	);
};

export default IWillNotDeliver;
