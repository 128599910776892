import { ApiContextState } from './types';

const date = new Date();

const initialState: ApiContextState = {
	loading: false,
	apiUrl: '/',
	tender: {
		tenderId: '',
		name: '',
		buyerName: '',
		contact: {
			firstName: '',
			lastName: '',
			fullname: '',
			emailAddress: '',
		},
		description: '',
		dueDate: date,
		duration: '',
		mainArea: '',
		caseNumber: '',
		evaluationMethod: '',
		contractType: '',
		procedure: '',
		questionsDueDate: date,
		bidValidUntilDate: date,
		awardCriteria: '',
		documents: [{ id: '', documentName: '' }],
		publicationDate: date,
		externalId: '',
	},
	invitation: {
		invitationId: '',
		invitationDate: date,
		invitationStatusCode: 0,
		invitationStatus: '',
		tenderId: '',
		buyerName: '',
		tenderName: '',
		dateOfAccept: date,
		dateOfDecline: date,
	},
	bid: {
		contactInformation: {
			company: { organizationNumber: '', companyName: '' },
			contact: {
				firstName: '',
				lastName: '',
				fullname: '',
				emailAddress: '',
			},
		},
		documents: [{ id: '', documentName: '' }],
		bidId: '',
		bidManagerId: '',
		tenderId: '',
		contactInformationConfirmed: false,
	},
	communication: {
		tenderId: '',
		directQuestions: [
			{
				id: '',
				bidManagerId: '',
				tenderId: '',
				title: '',
				description: '',
				sentDate: date,
				replyFromBuyer: '',
				replyDate: date,
			},
		],
		publicQuestions: [
			{
				question: '',
				questionText: '',
				answer: '',
				answerDate: date,
			},
		],
	},
	ongoings: {
		ongoingProcesses: [],
	},
	bidDelivery: {
		bidId: '',
		delivered: false,
		contactInformationConfirmed: false,
		deliveryStatus: '',
		dateModified: '',
		dateSubmitted: '',
	},
	error: undefined,
	resolved: false,
};

export default initialState;
