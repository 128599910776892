import { Alert, CheckmarkOutline24, Close24, MisuseOutline24 } from '@mercell/mercell-ui';
import React, { FC } from 'react';

interface BaseAlertProps {
	text: string;
	onClick: () => void;
	type: 'success' | 'danger' | 'info' | 'warning';
}

const BaseAlert: FC<BaseAlertProps> = ({ text, onClick, type }) => (
	<Alert scheme={type} className="my-4 relative">
		<div className="flex justify-between items-center">
			<div className="flex items-center">
				{type === 'danger' ? <MisuseOutline24 className="mr-2" /> : <CheckmarkOutline24 className="mr-2" />}
				<p>{text}</p>
			</div>
			<button
				type="button"
				onClick={onClick}
				className="absolute top-0 right-0 p-4 flex justify-center items-center"
			>
				<Close24 />
			</button>
		</div>
	</Alert>
);

export default BaseAlert;
