import { CLEAR_ALERT, CLEAR_TOAST, SET_ALERT, SHOW_TOAST } from './constants';
import { UiContextAction, UiContextState } from './types';

const uiReducer = (state: UiContextState, action: UiContextAction) => {
	if (action.type === SHOW_TOAST) {
		const newState = {
			...state,
			toast: {
				text: action.payload.text,
			},
		};

		return newState;
	}

	if (action.type === CLEAR_TOAST) {
		const newState = {
			...state,
			toast: {
				text: '',
			},
		};
		return newState;
	}

	if (action.type === SET_ALERT) {
		const newState = {
			...state,
			alerts: {
				...state.alerts,
				[action.payload.path]: [
					...(state.alerts[action.payload.path] || []),
					{
						message: action.payload.message,
						type: action.payload.type,
					},
				],
			},
		};
		return newState;
	}

	if (action.type === CLEAR_ALERT) {
		const { path, type } = action.payload;
		const newAlerts = state.alerts[path].filter((alert) => alert.type !== type);

		const newState = {
			...state,
			alerts: {
				...state.alerts,
				[action.payload.path]: [...newAlerts],
			},
		};
		return newState;
	}

	return state;
};

export default uiReducer;
