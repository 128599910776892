/* eslint-disable-line */
import './TenderMaterial.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import InvitationAlert from '../../../components/alerts/InvitationAlert/InvitationAlert';
import InvitationDeclinedAlert from '../../../components/alerts/InvitationDeclinedAlert/InvitationDeclinedAlert';
import ApiErrorResolver from '../../../components/ApiErrorResolver/ApiErrorResolver';
import Loader from '../../../components/Loader/Loader';
import ApiContext from '../../../providers/ApiProvider/ApiContext';
import TenderBody from './components/TenderBody/TenderBody';
import TenderHeader from './components/TenderHeader/TenderHeader';
import { useTenderStatuses } from './components/TenderStatus/TenderStatus';
import TenderTimeline from './components/TenderTimeline/TenderTimeline';

const TenderMaterial: React.FunctionComponent<RouteComponentProps> = () => {
	const { t } = useTranslation('tender_material');
	const { tender, invitation, bidDelivery, loading } = useContext(ApiContext);

	const status = useTenderStatuses(tender, invitation, bidDelivery);
	if (!status || !tender) return null;

	const { isPendingInvitation } = status;
	const isDeclined = invitation.invitationStatus === 'Declined';

	return (
		<Loader loading={loading}>
			<div className="sm:flex" data-cy="tender">
				<div className="space-y-4 bidmanager-tenderdetails-main">
					<TenderHeader data-loading />
					<div className="flex justify-between pt-2">
						<div className="space-y-4 w-full md:w-4/5 md:mr-20">
							<ApiErrorResolver />
							{isPendingInvitation && <InvitationAlert data-loading />}
							{isDeclined && <InvitationDeclinedAlert data-loading />}
							<TenderBody />
						</div>
						<div className="bidmanager-tenderdetails-aside" data-loading>
							<h3 className="mb-4">{t('heading-timeline')}</h3>
							<TenderTimeline tender={tender} data-loading />
						</div>
					</div>
				</div>
			</div>
		</Loader>
	);
};

export default TenderMaterial;
