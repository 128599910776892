import '@mercell/mercell-ui/dist/index.css';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import AppStandalone from './AppStandalone';
import AppProvider from './providers/AppProvider/AppProvider';
import * as serviceWorker from './serviceWorker';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		bidmanager: any;
	}
}

const rootEl = document.getElementById('root');

const fallbackLoading = (
	<div className="absolute block bg-white" style={{ width: '800px', height: '400px' }}>
		<div className="relative" style={{ left: '45%', top: '50%' }}>
			<img
				className="animate-spin"
				src="https://cdn.mercell.com/icons/Spinner_dark_bkgd_24px.svg"
				alt="loading"
				height={60}
				width={60}
			/>
		</div>
	</div>
);

if (rootEl) {
	ReactDOM.render(
		<React.StrictMode>
			<React.Suspense fallback={fallbackLoading}>
				<BrowserRouter>
					<AppProvider>
						<App />
					</AppProvider>
				</BrowserRouter>
			</React.Suspense>
		</React.StrictMode>,
		rootEl
	);
}

// Namespace functions under bidmanager on the global object
window.bidmanager = {};
window.bidmanager.renderBidding = (root: string, routePrefix: string, env: string, lang: string) => {
	ReactDOM.render(
		<React.StrictMode>
			<React.Suspense fallback={fallbackLoading}>
				<BrowserRouter>
					<AppProvider routePrefix={routePrefix}>
						<AppStandalone env={env} lang={lang} />
					</AppProvider>
				</BrowserRouter>
			</React.Suspense>
		</React.StrictMode>,
		document.getElementById(root)
	);
};

// window.renderBidding('root', 'bm');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
