import './QuestionsAccordion.scss';

import { ChevronDown24, useAccordionProps } from '@mercell/mercell-ui';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../../../components/Loader/Loader';
import { utcToUsersLocalDateAndTime } from '../../../../../../../dateTimeUtil';
import ApiContext from '../../../../../../../providers/ApiProvider/ApiContext';

interface QuestionAccordionProps {
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	id: string;
	expanded: boolean;
	title: string;
	answer: string;
	date: Date | null;
	question: string;
	className?: string;
}

const QuestionAccordion: FC<QuestionAccordionProps> = ({ onClick, id, expanded, title, question, answer, date }) => {
	const { trigger, panel } = useAccordionProps<HTMLDivElement>(id, expanded);
	const { loading } = useContext(ApiContext);
	const { t } = useTranslation('questions');

	const formatDate = () => {
		if (date) {
			return utcToUsersLocalDateAndTime(date);
		}
		return '';
	};

	const getDate = () => <span className="text-label text-grey-dark mr-24">{formatDate()}</span>;

	const getAnswer = () => {
		if (!expanded) return null;

		return (
			<div className="my-2 flex flex-col bg-info p-4 rounded">
				<p className="font-bold mb-2">{t('buyer-answer-title')}</p>
				{answer || t('buyer-no-answer')}
			</div>
		);
	};

	const buttonText = expanded ? t('show-less') : t('show-more');
	const chevronClasses = classnames('ml-1 transition-transform duration-300 ease-in-out', { 'rotate-180': expanded });

	return (
		<Loader loading={loading}>
			<div style={{ maxWidth: '776px' }} data-loading>
				<button
					type="button"
					onClick={onClick}
					{...trigger}
					className="accordion p-4 bg-grey-background flex items-start mt-1 flex-col md:flex-row md:justify-between relative flex-wrap focus:outline-none"
				>
					<div className="flex items-center justify-between bidmanager-question-accordion-container">
						<span className="bidmanager-question-accordion-title">{title}</span>
						<QuestionStatus answer={answer} />
						{getDate()}
						<span className="text-label flex items-center justify-between absolute bidmanager-question-accordion--button-container">
							<span className="bidmanager-question-accordion--buttonText">{buttonText}</span>{' '}
							<ChevronDown24 className={chevronClasses} />
						</span>
					</div>
				</button>
				<div {...panel} className="accordion-panel bg-grey-background">
					<div className="p-4">{question}</div>
				</div>
				{getAnswer()}
			</div>
		</Loader>
	);
};

interface QuestionStatusProps {
	answer: string;
}

const QuestionStatus: FC<QuestionStatusProps> = ({ answer }) => {
	const { t } = useTranslation('questions');
	if (answer) return <div className="bidmanager-question-accordion-status mr-20" />;
	return (
		<div className="text-label mr-20 flex lg:my-0 items-center bidmanager-question-accordion-status">
			<div className="bg-danger-light mr-2" style={{ width: '15px', height: '15px', borderRadius: '50%' }} />
			<span className="font-bold">{t('question-status')}</span>
		</div>
	);
};

export default QuestionAccordion;
