import React, { FC } from 'react';

import BaseAlert from '../BaseAlert/BaseAlert';

interface ErrorAlertProps {
	text: string;
	onClick: () => void;
}

const ErrorAlert: FC<ErrorAlertProps> = ({ text, onClick }) => (
	<BaseAlert type="danger" text={text} onClick={onClick} />
);

export default ErrorAlert;
