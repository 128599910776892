import { Alert } from '@mercell/mercell-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface InvitationDeclinedAlertProps {
	className?: string;
}

const InvitationDeclinedAlert: React.FC<InvitationDeclinedAlertProps> = ({ className }) => {
	const { t } = useTranslation('bidding');
	return (
		<Alert scheme="info" className={className}>
			<h3>{t('invitation-declined-heading')}</h3>
			<div>
				{t('invitation-declined-message-part1')}
				<a href="mailto:bidmanager@mercell.com">{t('invitation-declined-mailto')}</a>
				{t('invitation-declined-message-part2')}
			</div>
		</Alert>
	);
};

export default InvitationDeclinedAlert;
