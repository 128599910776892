/* eslint-env browser */
import { CloudUpload32 } from '@carbon/icons-react';
import { Button, useCombinedRefs, useDrag } from '@mercell/mercell-ui';
import cx from 'classnames';
import React, { MouseEventHandler, useMemo, useRef, useState } from 'react';

// import type { AlertConfig } from './hook';
import { createFileList, mergeFileLists } from './utils';

export interface FileUploaderProps<T = void> extends Omit<JSX.IntrinsicElements['input'], 'onChange'> {
	files?: File[];
	onChange?: (files: File[]) => void | Promise<T>;
	// alerts?: AlertConfig[];
	undo?: () => void;
	redo?: () => void;
	dragAndDropText?: string;
	browseButtonText?: string;
	sizeLimitText?: string;
}

const FileUploader = React.forwardRef<HTMLInputElement, FileUploaderProps>(
	(
		{
			id,
			onChange,
			files,
			// alerts,
			children,
			dragAndDropText = 'Drag & Drop files here',
			browseButtonText = 'Browse files',
			sizeLimitText,
			className,
			...rest
		},
		ref
	) => {
		const inputElement = useRef<HTMLInputElement>(null);
		const inputRef = useCombinedRefs(inputElement, ref);
		const [fileList, setFileList] = useState<FileList | undefined>(createFileList(files ?? []));

		const appendFilesToInputElement = (filesToAdd?: FileList | null) => {
			if (filesToAdd && inputElement.current) {
				const updatedFileList = fileList ? mergeFileLists(fileList, filesToAdd) : filesToAdd;
				inputElement.current.files = updatedFileList;
				inputElement.current.dispatchEvent(new Event('input'));
				setFileList(updatedFileList);
			}
		};

		const { dragging, ...dragFns } = useDrag({
			onDrop: (e) => {
				if (onChange) onChange(Array.from(e.dataTransfer?.files));
				appendFilesToInputElement(e.dataTransfer?.files);
			},
		});

		const openFileSelect: MouseEventHandler<HTMLButtonElement> = (e) => {
			e.preventDefault();
			if (inputElement.current) inputElement.current.click();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const isInline = useMemo(() => (files?.length ?? 0) > 0, [files]);

		return (
			<div
				{...dragFns}
				className={cx('p-4 border-dashed border-main border-2 rounded', className, { 'opacity-50': dragging })}
			>
				<div className={cx('flex items-center w-full', isInline ? 'justify-end relative' : 'flex-col')}>
					<input
						id={id}
						type="file"
						className="hidden"
						multiple
						ref={inputRef}
						onChange={(e) => {
							if (onChange) onChange(Array.from(e.target?.files ?? []));
							appendFilesToInputElement(e.target?.files);
						}}
						{...rest}
					/>
					<div
						className={cx('flex items-center', {
							'flex-col': !isInline,
						})}
					>
						<CloudUpload32 className="hidden sm:block w-8 h-8 fill-current m-0" />
						<span
							className={cx('hidden sm:block font-medium text-xl', {
								'ml-2 mr-4': isInline,
								'pb-4': !isInline,
							})}
						>
							{dragAndDropText}
						</span>
					</div>

					<Button
						scheme="secondary"
						aria-label={browseButtonText}
						type="button"
						onClick={openFileSelect}
						className={cx('block')}
					>
						{browseButtonText}
					</Button>
					{!isInline && sizeLimitText && <p className="text-small mt-4">{sizeLimitText}</p>}
				</div>
				{/* {alerts?.map((alert) => (
                    <Alert
                        className="mt-2"
                        key={alert.description}
                        {...alert}
                    />
                ))} */}
				{children}
			</div>
		);
	}
);

export default FileUploader;
