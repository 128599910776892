/* eslint-env browser */
import * as React from 'react';

import { User } from '../../api';

interface IAuthContext {
	isLoggedIn: boolean;
	user?: User;
	setLoggedIn: (user: User) => void;
	setLoggedOut: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
	isLoggedIn: false,
	setLoggedIn: (user: User) => undefined,
	setLoggedOut: () => undefined,
});

export const AuthContextProvider: React.FunctionComponent<{
	initialUser?: User;
}> = ({ children, initialUser }) => {
	const [context, setContext] = React.useState<Omit<IAuthContext, 'setLoggedIn' | 'setLoggedOut'>>({
		isLoggedIn: !!initialUser,
		user: initialUser,
	});

	const setLoggedOut = () => {
		setContext({ ...context, isLoggedIn: false, user: undefined });
	};

	const setLoggedIn = (user: User) => {
		setContext({ ...context, isLoggedIn: true, user });
	};

	return (
		<AuthContext.Provider
			value={{
				...context,
				setLoggedIn,
				setLoggedOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => React.useContext(AuthContext);

export default IAuthContext;
