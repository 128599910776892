import { Button } from '@mercell/mercell-ui';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';

import Loader from '../../../../../components/Loader/Loader';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import { IQuestion } from '../../types';
import NoQuestions from './NoQuestions/NoQuestions';
import QuestionsList from './QuestionsList/QuestionsList';

interface QuestionsOverviewProps {
	directQuestionsList: IQuestion[];
	publicQuestionsList: IQuestion[];
}

const QuestionsOverview: FC<RouteComponentProps & QuestionsOverviewProps> = ({
	directQuestionsList,
	publicQuestionsList,
	match,
}) => {
	const { loading } = useContext(ApiContext);
	const { t } = useTranslation('questions');

	const empty = directQuestionsList.length === 0 && publicQuestionsList.length === 0;
	if (empty) {
		return <NoQuestions />;
	}

	const renderCommonMarkup = () => (
		<Link to={`${match.url}/new-question`}>
			<Loader loading={loading}>
				<Button scheme="primary" className="my-8" data-loading style={{ maxWidth: '200px' }}>
					{t('post-new-question')}
				</Button>
			</Loader>
		</Link>
	);

	const renderSingleList = (list: IQuestion[]) => (
		<>
			<QuestionsList questions={list} />
			{renderCommonMarkup()}
		</>
	);

	const showBothLists = directQuestionsList.length > 0 && publicQuestionsList.length > 0;
	if (showBothLists) {
		return (
			<>
				<QuestionsList questions={directQuestionsList} title={t('private-title')} />
				<QuestionsList questions={publicQuestionsList} title={t('public-title')} />
				{renderCommonMarkup()}
			</>
		);
	}

	const onlyDirectQuestionsList = directQuestionsList.length > 0;
	if (onlyDirectQuestionsList) {
		return renderSingleList(directQuestionsList);
	}

	const onlyPublicQuestionsList = publicQuestionsList.length > 0;
	if (onlyPublicQuestionsList) {
		return renderSingleList(publicQuestionsList);
	}

	return null;
};

export default QuestionsOverview;
