import * as React from 'react';
import { FC } from 'react';

import { AuthContextProvider } from '../../pages/Login/AuthContext';
import { User, user as loadedUser } from '../../pages/Login/Login';
import ApiProvider from '../ApiProvider/ApiProvider';
import RouteProvider from '../RouteProvider/RouteProvider';
import UiProvider from '../UiProvider/UiProvider';

interface AppProviderProps {
	routePrefix?: string;
	user?: User;
}

const AppProvider: FC<AppProviderProps> = ({ children, routePrefix = '', user = loadedUser.read() }) => (
	<AuthContextProvider initialUser={user}>
		<RouteProvider routePrefix={routePrefix}>
			<UiProvider>
				<ApiProvider>{children}</ApiProvider>
			</UiProvider>
		</RouteProvider>
	</AuthContextProvider>
);

export default AppProvider;
