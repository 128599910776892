import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalenderIcon } from '../../../../../assets/icons/calendar-color.svg';
import Loader from '../../../../../components/Loader/Loader';
import { Tender } from '../../../../../contracts/tender';
import { datetimeDifference } from '../../../../../dateTimeUtil';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';

const TenderDueDate: React.FunctionComponent<Tender> = (tender) => {
	const { t } = useTranslation('tender_material');
	const { loading } = useContext(ApiContext);
	const { dueDate } = tender;

	const getDifferenceString = () => datetimeDifference(dueDate);

	const dueDatePassed = new Date(dueDate) < new Date();
	return (
		<Loader loading={loading}>
			<div data-loading>
				{dueDatePassed && (
					<div className="flex flex-wrap rounded items-center">
						<CalenderIcon className="mr-2" />
						{t('due-date-passed-status')}
					</div>
				)}
				{!dueDatePassed && (
					<div className="flex flex-wrap rounded items-center">
						<CalenderIcon className="mr-2" />
						{t('due-date-in')}
						<span className="ml-1 font-bold">{getDifferenceString()}</span>
					</div>
				)}
			</div>
		</Loader>
	);
};

export default TenderDueDate;
