import './BidManagementLayout.scss';

import { Breadcrumbs } from '@mercell/mercell-ui';
import React, { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Loader from '../../../../../components/Loader/Loader';
import Sidebar from '../../../../../components/Sidebar/Sidebar';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';

function formatTenderName(tenderName: string): string {
	let name = tenderName;
	if (tenderName.length > 20) {
		name = `${tenderName.substring(0, 8)}... ${tenderName.slice(-9)}`;
	}

	return name;
}
function getBreadCrumbUrls(tenderName: string, path: string): Array<{ link: string; name: string }> {
	const breadCrumbs: Array<{ link: string; name: string }> = [];
	let breadCrumb = { link: '/', name: 'Home' };
	breadCrumbs.push(breadCrumb);
	const breadCrumbTender = { link: path, name: formatTenderName(tenderName) };
	if (path.indexOf('/bidding') > -1) {
		breadCrumbTender.link = path.substr(0, path.indexOf('/bidding'));
		breadCrumbs.push(breadCrumbTender);
		breadCrumb = { link: path, name: 'Bid process' };
		breadCrumbs.push(breadCrumb);
	} else if (path.indexOf('/communication') > -1) {
		breadCrumbTender.link = path.substr(0, path.indexOf('/communication'));
		breadCrumbs.push(breadCrumbTender);
		breadCrumb = { link: path, name: 'Communication' };
		breadCrumbs.push(breadCrumb);
	} else {
		breadCrumbs.push(breadCrumbTender);
	}

	return breadCrumbs;
}
interface BidManagementLayoutProps {
	tenderId: string;
}

const BidManagementLayout: FC<BidManagementLayoutProps> = ({ children, tenderId }) => {
	const { tender, loading } = useContext(ApiContext);
	const location = useLocation();
	const breadCrumbLinks: Array<{
		link: string;
		name: string;
	}> = getBreadCrumbUrls(tender.name, location.pathname);
	const linkElements = breadCrumbLinks.map((item) => (
		<Link key={item.link} to={item.link}>
			{item.name}
		</Link>
	));

	return (
		<div className="flex flex-col md:flex-row h-full w-full">
			<Sidebar tenderId={tenderId} />
			<div className="p-6 md:py-8 md:px-20 bg-white w-full flex flex-col flex-grow">
				<div className="bid-management-layout-container">
					<Loader loading={loading}>
						<Breadcrumbs data-loading className="mb-10">
							{linkElements}
						</Breadcrumbs>
					</Loader>
					{children}
				</div>
			</div>
		</div>
	);
};

export default BidManagementLayout;
