/* Libraries */
import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps } from 'react-router-dom';

import Loader from '../../../components/Loader/Loader';
import ApiContext from '../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../providers/RouteProvider/RouteContext';
import InvitationDeclined from '../Bidding/pages/InvitationDeclined/InvitationDeclined';
import BidSimpleEdit from './BidSimpleEdit';
/* Components */
import BidSimpleSummary from './BidSimpleSummary';
import BidSimpleUpload from './BidSimpleUpload';
import { IntegrationProps } from './interfaces';

const SimpleBiddingResolver: FC<RouteComponentProps> = ({ history }) => {
	const { formatPath } = useContext(RouteContext);
	const { bid, bidDelivery } = useContext(ApiContext);

	useEffect(() => {
		resolve(bidDelivery.deliveryStatus);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bidDelivery.deliveryStatus]);

	const resolve = (status: string) => {
		switch (status) {
			case 'UploadDocuments':
			case 'ConfirmContactInformation':
				history.push(
					formatPath(
						`tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(bid.bidId)}/upload`
					)
				);
				break;
			case 'Editing':
				history.push(
					formatPath(
						`/tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(bid.bidId)}/edit`
					)
				);
				break;
			case 'Submitted':
			case 'Summary':
				history.push(
					formatPath(
						`tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(
							bid.bidId
						)}/summary-readonly`
					)
				);
				break;
			default:
				break;
		}
	};

	return <></>;
};

const SimpleBiddingRouting: FC<RouteComponentProps & IntegrationProps> = ({ match, isIntegration }) => {
	const { formatPath } = useContext(RouteContext);
	const { loading, bid } = useContext(ApiContext);
	const { t } = useTranslation('dashboard');

	if (!bid.tenderId) return null;

	return (
		<Loader loading={loading}>
			<div className="flex flex-col">
				<h1 className="mb-4" data-cy="bid-process" data-loading>
					{t('bid-delivery-action')}
				</h1>
				<Route
					exact
					path={[
						formatPath('/'),
						formatPath(match.url),
						formatPath(`tender/${encodeURIComponent(bid.tenderId)}/bidding`),
					]}
					render={(props) => <SimpleBiddingResolver {...props} />}
				/>
				<Route
					path={formatPath(`tender/${encodeURIComponent(bid.tenderId)}/bidding/:bidId/upload`)}
					render={(props) => <BidSimpleUpload {...props} isIntegration={isIntegration} />}
				/>
				<Route
					path={formatPath(`tender/${encodeURIComponent(bid.tenderId)}/bidding/:bidId/summary-readonly`)}
					render={(props) => <BidSimpleSummary {...props} isIntegration={isIntegration} />}
				/>
				<Route
					path={formatPath(`tender/${encodeURIComponent(bid.tenderId)}/bidding/:bidId/edit`)}
					render={(props) => <BidSimpleEdit {...props} isIntegration={isIntegration} />}
				/>
				<Route path={formatPath(`invitation-declined`)} render={() => <InvitationDeclined />} />
			</div>
		</Loader>
	);
};

export default SimpleBiddingRouting;
