import { OngoingProcess } from '../../contracts/dashboard';

export const isPendingInvitationStatus = (process: OngoingProcess): boolean => process.status === 'PendingInvitation';
export const isInvitationDeclinedStatus = (process: OngoingProcess): boolean => process.status === 'InvitationDeclined';
export const isBidInProgressStatus = (process: OngoingProcess): boolean => process.status === 'BidInProgress';
export const isBidSubmittedStatus = (process: OngoingProcess): boolean => process.status === 'BidSubmitted';
export const isDueDatePassedStatus = (process: OngoingProcess): boolean => process.status === 'DueDatePassed';
export const isBidWithDrawn = (process: OngoingProcess): boolean => process.status === 'BidWithdrawn';
export const isWaitingForEvaluationStatus = (process: OngoingProcess): boolean =>
	process.status === 'WaitingForEvaluation';

export function getOngoingProcessStatus(process: OngoingProcess) {
	return {
		isPendingInvitation: isPendingInvitationStatus(process),
		isInvitationDeclined: isInvitationDeclinedStatus(process),
		isBidInProgress: isBidInProgressStatus(process),
		isBidSubmitted: isBidSubmittedStatus(process),
		isDueDatePassed: isDueDatePassedStatus(process),
		isBidWithDrawn: isBidWithDrawn(process),
		isWaitingForEvaluation: isWaitingForEvaluationStatus(process),
	};
}
