import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteChildrenProps } from 'react-router-dom';

import { ReactComponent as NotificationIcon } from '../../assets/icons/notification-page.svg';

const Notification: FC<RouteChildrenProps> = () => {
	const { t } = useTranslation('common');

	return (
		<div className="w-full h-full flex justify-center items-start md:items-center bg-white p-4 flex-grow">
			<div
				className="flex flex-col md:flex-row justify-center items-center md:-translate-y-24"
				style={{ maxWidth: '650px' }}
			>
				<div className="mr-4">
					<h1 className="mt-8 md:mt-0">{t('notification-center-header')}</h1>
					<p className="mt-8">{t('no-notifications-text')}</p>
				</div>
				<div className="hidden md:block">
					<NotificationIcon className="md:translate-y-10" style={{ width: '225px' }} />
				</div>
			</div>
		</div>
	);
};

export default Notification;
