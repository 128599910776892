import { Launch20 } from '@mercell/mercell-ui';
import * as React from 'react';

import Loader from '../../../components/Loader/Loader';
import ApiContext from '../../../providers/ApiProvider/ApiContext';

interface FeedbackLinkProps {
	question: string;
	linkText: string;
	url: string;
}

const FeedbackLink: React.FC<FeedbackLinkProps> = ({ question, linkText, url }) => {
	const { loading } = React.useContext(ApiContext);
	return (
		<Loader loading={loading}>
			<div className="space-y-2 mt-5" data-loading>
				<p>{question}</p>
				<a
					href={url}
					target="_blank"
					rel="noreferrer"
					className="flex items-center text-primary hover:text-primary-hover hover:cursor-pointer hover:underline"
				>
					{linkText}
					<Launch20 className="ml-1 fill-current" />
				</a>
			</div>
		</Loader>
	);
};

export default FeedbackLink;
