import { Button } from '@mercell/mercell-ui';
import * as React from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Loader from '../../../../../../components/Loader/Loader';
import { PostQuestionRequest } from '../../../../../../contracts/communication';
import ApiContext from '../../../../../../providers/ApiProvider/ApiContext';

interface NewQuestionFormProps {
	onSubmit: (data: PostQuestionRequest) => void;
}

const NewQuestionForm: React.FC<NewQuestionFormProps> = ({ onSubmit }) => {
	const { loading } = useContext(ApiContext);
	const { register, handleSubmit, errors } = useForm<PostQuestionRequest>();
	const { goBack } = useHistory();
	const { t } = useTranslation('questions');
	const requiredFieldMessage = t('field-required');

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
			<Loader loading={loading}>
				<div data-loading>
					<label htmlFor="title">
						{t('new-question-title-label')}
						<input id="title" ref={register({ required: requiredFieldMessage })} name="title" />
					</label>
					{errors.title?.message && <p className="text-danger-accent">{errors.title?.message}</p>}
				</div>
				<div data-loading>
					<label htmlFor="question">
						{t('new-question-text-label')}
						<textarea
							id="question"
							ref={register({ required: requiredFieldMessage })}
							name="question"
							rows={6}
							style={{ height: '152.5px' }}
						/>
					</label>
					{errors.title?.message && <p className="text-danger-accent">{errors.title?.message}</p>}
				</div>
				<div className="flex space-x-4">
					<Button scheme="primary" type="submit" data-loading>
						{t('post-question')}
					</Button>
					<Button scheme="tertiary" type="reset" onClick={goBack} data-loading>
						{t('cancel')}
					</Button>
				</div>
			</Loader>
		</form>
	);
};

export default NewQuestionForm;
