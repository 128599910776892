/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';

const initialState = {
	routePrefix: '',
	formatPath: (path: string) => '',
};

const RouteContext = React.createContext(initialState);

export default RouteContext;
