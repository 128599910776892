import './UploadDocumentsForm.scss';

import { Download32, TrashCan32 } from '@mercell/mercell-ui';
import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../components/Loader/Loader';
import { Bid } from '../../../../../contracts/bidding';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import filterFileList from '../../pages/UploadDocuments/utils';
import { FileList, ListFile } from './file-uploader/FileList';
import FileUploader from './file-uploader/FileUploader';

interface UploadDocumentsFormProps {
	bid: Bid;
	files: (File | ListFile)[];
	register: ReturnType<typeof useForm>['register'];
	setValue: ReturnType<typeof useForm>['setValue'];
	showHeader?: boolean;
	onUpload?: (files: (File | ListFile)[]) => void;
	onRemove?: (files: File[]) => void;
}

export type UploadDocumentsFormData = {
	bidId: string;
	uploadedFiles: FileList;
};

const UploadDocumentsForm: FC<UploadDocumentsFormProps> = ({
	bid,
	register,
	files,
	setValue,
	showHeader = false,
	onUpload,
	onRemove,
}) => {
	const { t } = useTranslation('bidding');
	const { loading, apiUrl } = useContext(ApiContext);
	const getFileUrl = (file: File) => {
		if (file.size > 0) {
			return URL.createObjectURL(file);
		}
		// Find the document id in the list of documents already uploaded
		// to the server
		const documentId = bid.documents.find((doc) => doc.documentName === file.name)?.id;

		return `${apiUrl}/api/File/download/${encodeURIComponent(documentId || '')}`;
	};

	const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);

	return (
		<Loader loading={loading}>
			<input readOnly hidden value={encodeURIComponent(bid.bidId)} ref={register} name="bidId" />
			<div>
				{showHeader && (
					/* eslint-disable-next-line jsx-a11y/label-has-associated-control */
					<label htmlFor="file-uploader">
						<h2 className="mt-10 mb-4" data-loading>
							{t('upload-documents-heading')}
						</h2>
					</label>
				)}
				<FileUploader
					id="file-uploader"
					name="uploadedFiles"
					dragAndDropText={t('drag-and-drop-text')}
					browseButtonText={t('browse-files-text')}
					files={files}
					ref={register}
					className="bidmanager-upload-form"
					data-loading
					onChange={onUpload}
				>
					<FileList
						id="file-uploader-list"
						files={files}
						selectAllLabel={t('select-all-text')}
						selected={selectedFiles}
						setSelected={setSelectedFiles}
						actions={[
							{
								label: t('download-label'),
								schemeType: 'vertical',
								handler: (fileList) => {
									// We must distinguish between files uploaded to the server and files
									// ready for upload
									// files already uploaded will have zero byte content as they only serve as link
									// initiating the download from the server.
									fileList.forEach((file) => {
										const url = getFileUrl(file);

										const link = document.createElement('a');
										link.download = file.name;
										link.href = url;
										link.click();
										link.remove();
									});
								},
								Icon: Download32,
							},
							{
								label: t('remove-label'),
								schemeType: 'vertical',
								handler: (removedFiles) => {
									if (onRemove) {
										onRemove(removedFiles);
										const selectedAfterRemove = selectedFiles.filter((selectedFile) =>
											removedFiles.every((file) => file.name !== selectedFile.name)
										);
										setSelectedFiles(selectedAfterRemove);
									}
									setValue('uploadedFiles', filterFileList(removedFiles, files), {
										shouldDirty: true,
									});
								},
								Icon: TrashCan32,
							},
						]}
					/>
				</FileUploader>
				<p className="text-regular mt-4" data-loading>
					{t('info-text-file-upload')}
				</p>
			</div>
		</Loader>
	);
};

export default UploadDocumentsForm;
