import './AcceptInvitation.scss';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BidProcessIcon } from '../../../../../assets/icons/bid-process.svg';
import InvitationAlert from '../../../../../components/alerts/InvitationAlert/InvitationAlert';
import ApiErrorResolver from '../../../../../components/ApiErrorResolver/ApiErrorResolver';
import Loader from '../../../../../components/Loader/Loader';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';

const AcceptInvitation: React.FunctionComponent = () => {
	const { loading } = useContext(ApiContext);
	const { t } = useTranslation('bidding');

	return (
		<div className="flex-col">
			<Loader loading={loading}>
				<main className="space-y-4">
					<h1 data-loading>{t('bidding-header')}</h1>
					<div className="alert-container" data-loading>
						<ApiErrorResolver />
						<InvitationAlert />
					</div>
				</main>
				<aside className="hidden sm:block translate-x-10">
					<BidProcessIcon className="ml-auto" data-loading />
				</aside>
			</Loader>
		</div>
	);
};

export default AcceptInvitation;
