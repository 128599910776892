import Axios from 'axios';

import { LoginForm } from './contracts/authentication';
import { Bid, BidDelivery, Document } from './contracts/bidding';
import { CommunicationResponse, PostQuestionRequest } from './contracts/communication';
import { Invitation, OngoingProcessesResponse } from './contracts/dashboard';
import { AcceptInvitationRequest, DeclineInvitationRequest } from './contracts/invitation';
import { CreateBidResponse, DocumentsResponse, Tender } from './contracts/tender';
import { DEFAULT_API_URL } from './providers/ApiProvider/constants';
import { wrapPromise } from './utils';

const axiosInstance = Axios.create({
	withCredentials: true,
	baseURL: '/',
});

// Export function to setup axios with embedded modules
export const setupAxiosInstance = (apiUrl: string) =>
	new Promise<void>((resolve) => {
		if (apiUrl === DEFAULT_API_URL) return;
		axiosInstance.interceptors.request.use((config) => {
			// eslint-disable-next-line no-param-reassign
			config.baseURL = apiUrl;
			return config;
		});
		resolve();
	});

// #region user login
export type User = {
	username: string;
	profilePictureUrl?: string;
};

const getUser = async () => {
	try {
		const { data } = await axiosInstance.get<User>('/api/user');
		return data;
	} catch {
		return undefined;
	}
};

export const user = wrapPromise(getUser());
// #endregion

// #region bidding
export const withdrawBid = (bidId: string) => axiosInstance.put(`/api/bids/${encodeURIComponent(bidId)}/withdraw`);

export const resumeBid = (bidId: string) => axiosInstance.put(`/api/bids/${encodeURIComponent(bidId)}/resume`);

export const bidModifiedSinceLastSubmit = (bidId: string) =>
	axiosInstance.get(`/api/bids/${encodeURIComponent(bidId)}/bid-modified-since-last-submit`);
// #endregion

// #region tender material
export const getTender = (tenderId: string) =>
	axiosInstance.get<Tender>(`/api/tenders/${encodeURIComponent(tenderId)}`);

export const startDelivery = (tenderId: string) =>
	axiosInstance.post<CreateBidResponse>(`/api/bids?tenderId=${encodeURIComponent(tenderId)}`);

export const getInvitation = (tenderId: string) =>
	axiosInstance.get<Invitation>(`/api/tender/${encodeURIComponent(tenderId)}/invitation`);

export const acceptInvitation = (request: AcceptInvitationRequest) =>
	axiosInstance.post(`/api/invitation/accept`, request);

export const declineInvitation = (request: DeclineInvitationRequest) =>
	axiosInstance.post(`/api/invitation/decline`, request);

export const getDocuments = (tenderId: string) =>
	axiosInstance.get<DocumentsResponse>(`/api/file/tender/${encodeURIComponent(tenderId)}`);

export const iWillNotDeliver = (invitationId: string) =>
	axiosInstance.put(`/api/invitations/${encodeURIComponent(invitationId)}/i-will-not-deliver`);

export const getBid = (bidId: string) => axiosInstance.get<Bid>(`/api/bids/${encodeURIComponent(bidId)}`);

export const getBidByTenderId = (tenderId: string) =>
	axiosInstance.get<Bid>(`/api/tenders/${encodeURIComponent(tenderId)}/bid`);

export const getBidDelivery = (bidId: string) =>
	axiosInstance.get<BidDelivery>(`/api/bids/${encodeURIComponent(bidId)}/delivery`);

export const getTenderCommunication = (tenderId: string) =>
	axiosInstance.get<CommunicationResponse>(`/api/communication/${encodeURIComponent(tenderId)}`);

export const postQuestion = (sendQuestionRequest: PostQuestionRequest, tenderId: string) =>
	axiosInstance.post(`/api/communication/${encodeURIComponent(tenderId)}`, sendQuestionRequest);

export const confirmContactInformation = (formData: FormData, bidId: string) =>
	axiosInstance.put(`/api/bids/${encodeURIComponent(bidId)}/confirm-contactinformation`, formData);

export const login = (payload: LoginForm) => axiosInstance.post('/api/user/login', payload);

export const logout = () => axiosInstance.get('/api/user/logout');

export const getOngoingProcesses = () =>
	axiosInstance.get<OngoingProcessesResponse>('/api/dashboard/ongoing-processes');

export const submitBid = (bidId: string) => axiosInstance.put(`/api/bids/${encodeURIComponent(bidId)}/submit`);

export const editBid = (bidId: string) => axiosInstance.put(`/api/bids/${encodeURIComponent(bidId)}/edit`);

export const cancelEditBid = (bidId: string) => axiosInstance.put(`/api/bids/${encodeURIComponent(bidId)}/cancel-edit`);
// #endregion

export const uploadDocument = (data: FormData) => axiosInstance.post(`/api/File/upload`, data);

export const deleteDocument = (doc: Document) => axiosInstance.delete(`/api/File/delete/${encodeURIComponent(doc.id)}`);
