import { Card } from '@mercell/mercell-ui';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { OngoingProcess } from '../../../../contracts/dashboard';
import { utcToUsersLocalDate } from '../../../../dateTimeUtil';
import RouteContext from '../../../../providers/RouteProvider/RouteContext';
import * as selectors from '../../selectors';
import OngoingProcessActions from '../OngoingProcessActions/OngoingProcessActions';
import OngoingProcessStatus from '../OngoingProcessStatus/OngoingProcessStatus';

export interface OngoingProcessCardProps {
	ongoingProces: OngoingProcess;
	className?: string;
	iHaveChanged: () => void;
}

const OngoingProcessCard: React.FunctionComponent<OngoingProcessCardProps> = (props) => {
	const { formatPath } = useContext(RouteContext);
	const { t } = useTranslation('dashboard');
	const { ongoingProces } = props;
	const { invitation } = ongoingProces;

	const status = selectors.getOngoingProcessStatus(ongoingProces);
	const showDueDate = !ongoingProces.tenderDueDatePassed || status.isDueDatePassed;
	const dueDate = utcToUsersLocalDate(ongoingProces.tenderDueDate);

	const { className } = props;

	return (
		<Card
			className={classnames('bg-white mb-4 border border-grey-light invitation', className)}
			data-cy="invitationComponent"
		>
			<div className="flex flex-col">
				<div className="flex flex-col md:flex-row justify-between">
					<Link
						className="text-medium text-black"
						data-cy="showTenderDetails"
						to={formatPath(`/tender/${encodeURIComponent(invitation.tenderId)}`)}
					>
						{invitation.tenderName}
					</Link>
					<div>
						{showDueDate ? (
							<span>
								{t('due-date')}
								{dueDate}
							</span>
						) : (
							<i>{t('due-date-passed-status')}</i>
						)}
					</div>
				</div>
			</div>

			<div className="flex flex-col md:flex-row justify-between">
				<div>
					<p>{invitation.buyerName}</p>
				</div>
				<OngoingProcessStatus {...ongoingProces} />
			</div>

			<OngoingProcessActions ongoingProcess={ongoingProces} />
		</Card>
	);
};

export default OngoingProcessCard;
