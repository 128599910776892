import './InvitationDeclined.scss';

import { Button } from '@mercell/mercell-ui';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BidProcessIcon } from '../../../../../assets/icons/bid-process.svg';
import InvitationDeclinedAlert from '../../../../../components/alerts/InvitationDeclinedAlert/InvitationDeclinedAlert';
import RouteContext from '../../../../../providers/RouteProvider/RouteContext';

const InvitationDeclined: React.FunctionComponent = () => {
	const { push } = useHistory();
	const { formatPath } = useContext(RouteContext);
	const { t } = useTranslation('bidding');

	return (
		<div className="flex-col">
			<main className="space-y-4">
				<h1>{t('bidding-header')}</h1>
				<InvitationDeclinedAlert className="alert-container" />
				<div className="sm:flex">
					<Button
						scheme="tertiary"
						onClick={async () => {
							push(formatPath('/dashboard'));
						}}
					>
						{t('to-dashboard')}
					</Button>
				</div>
			</main>
			<aside className="hidden sm:block translate-x-10">
				<BidProcessIcon className="ml-auto" />
			</aside>
		</div>
	);
};

export default InvitationDeclined;
