import classnames from 'classnames';
import * as React from 'react';
import { FC } from 'react';

interface LoaderProps {
	loading: boolean;
}

const Loader: FC<LoaderProps> = ({ loading, children }) => {
	const addClassToReactNode = React.useCallback(
		(node: React.ReactNode) => {
			if (!React.isValidElement(node)) return node;

			return React.cloneElement(node, {
				...node.props,
				className: classnames(node.props.className, {
					loading,
					'animate-pulse': loading,
				}),
			});
		},
		[loading]
	);

	const addLoaderClasses = React.useCallback(
		(childrenCopy: React.ReactNode): React.ReactNode =>
			React.Children.map(childrenCopy, (child: React.ReactNode) => {
				let newChild = child;

				if (React.isValidElement(newChild)) {
					const newChildren = addLoaderClasses(newChild.props.children);
					newChild = React.cloneElement(newChild, newChild.props, newChildren);

					if (React.isValidElement(newChild) && newChild.props['data-loading']) {
						return addClassToReactNode(newChild);
					}
				}
				return newChild;
			}),
		[addClassToReactNode]
	);

	return <>{addLoaderClasses(children)}</>;
};

export default Loader;
