import { AppLayout, Footer } from '@mercell/mercell-ui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../Header/Header';

const Layout: FC = ({ children }) => {
	const { t } = useTranslation('common');

	return (
		<AppLayout className="bidmanager-app-layout">
			<Header />
			{children}
			<Footer
				contactEmail={t('contact-email-footer')}
				contactInfoHeader={t('contact-info-heading-footer')}
				phoneNumber={t('phone-number-footer')}
			/>
		</AppLayout>
	);
};

export default Layout;
