import { CLEAR_ERROR, CLEAR_LOADING, ERROR, LOADING, SUCCESS } from './constants';
import { ApiContextState, ApiProviderAction } from './types';

const apiReducer = (state: ApiContextState, action: ApiProviderAction) => {
	if (action.type === LOADING) {
		return { ...state, loading: true, error: undefined };
	}

	if (action.type === SUCCESS) {
		return { ...state, loading: false, ...action.payload };
	}

	if (action.type === ERROR) {
		return { ...state, loading: false, ...action.payload };
	}

	if (action.type === CLEAR_ERROR) {
		return { ...state, loading: false, error: undefined };
	}

	if (action.type === CLEAR_LOADING) {
		return { ...state, loading: false };
	}

	if (action.type === 'SET_API_URL') {
		return { ...state, apiUrl: action?.payload?.apiUrl || state.apiUrl };
	}

	return state;
};

export default apiReducer;
