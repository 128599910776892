import { Alert, Button } from '@mercell/mercell-ui';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ApiContext from '../../../providers/ApiProvider/ApiContext';
import UiContext from '../../../providers/UiProvider/UiContext';

interface InvitationAlertProps {
	className?: string;
}

const InvitationAlert: FC<InvitationAlertProps> = ({ className }) => {
	const { onAcceptInvitation, onDeclineInvitation, invitation } = useContext(ApiContext);
	const { setToast } = useContext(UiContext);
	const { t } = useTranslation('bidding');

	const { invitationId } = invitation;

	const handleDeclineInvitation = async () => {
		onDeclineInvitation(decodeURIComponent(invitationId)).then(() => {
			setToast('Invitation declined');
		});
	};

	const handleAcceptInvitation = async () => {
		onAcceptInvitation(decodeURIComponent(invitationId)).then(() => {
			setToast('Invitation accepted');
		});
	};

	return (
		<Alert scheme="info" className={className}>
			<div className="flex items-center justify-between bidmanager-tender-accept-invitation-alert">
				<div>
					<h3 className="text-base">{t('invitation-heading')}</h3>
					<p>{t('invitation-message')}</p>
				</div>
				<div className="flex space-x-4 my-4 lg:my-0">
					<Button data-cy="declineInvitationButton" scheme="tertiary" onClick={handleDeclineInvitation}>
						{t('decline')}
					</Button>
					<Button data-cy="acceptInvitationButton" scheme="primary" onClick={handleAcceptInvitation}>
						{t('accept')}
					</Button>
				</div>
			</div>
		</Alert>
	);
};

export default InvitationAlert;
