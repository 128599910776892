import { Button, Card, CheckmarkFilled24, CloseButton, Misuse24 } from '@mercell/mercell-ui';
import React, { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { ReactComponent as CloudIcon } from '../../../assets/icons/cloud.svg';
import ErrorAlert from '../../../components/alerts/ErrorAlert/ErrorAlert';
import Loader from '../../../components/Loader/Loader';
import { Bid } from '../../../contracts/bidding/bid';
import ApiContext from '../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../providers/RouteProvider/RouteContext';
import UiContext from '../../../providers/UiProvider/UiContext';
import { checkDueDate } from '../../../utils';
import { ListFile } from '../Bidding/components/UploadDocumentsForm/file-uploader/FileList';
import UploadDocumentsForm, {
	UploadDocumentsFormData,
} from '../Bidding/components/UploadDocumentsForm/UploadDocumentsForm';
import Modal from '../Bidding/pages/BidSummaryReadOnly/Modal';
import {
	convertToListFiles,
	setDuplicateFileAlert,
	setEmptyFileAlert,
	setRemovedFilesToast,
	setUploadedFilesToast,
} from '../Bidding/pages/UploadDocuments/utils';
import TenderDueDate from '../TenderMaterial/components/TenderDueDate/TenderDueDate';
import BiddingAlert from './BiddingAlert';
import { IntegrationProps } from './interfaces';

const createFileList = (bid: Bid) => {
	const formFileList = new DataTransfer();
	const bidDocuments = bid.documents.map((doc) => new File([''], doc.documentName));

	if (bidDocuments) {
		bidDocuments.forEach((file) => {
			formFileList.items.add(file);
		});
	}

	return formFileList.files;
};

const BidSimpleUpload: FC<RouteComponentProps & IntegrationProps> = ({ match, isIntegration }) => {
	const { formatPath } = useContext(RouteContext);
	const { t } = useTranslation(['bidding', 'common']);
	const { push } = useHistory();
	const {
		onUploadDocuments,
		onRemoveDocuments,
		onConfirmContactInformation,
		onSubmitBid,
		bid,
		tender,
		loading,
		error,
		clearError,
	} = useContext(ApiContext);
	const { state, setToast, setAlert, clearAlert } = useContext(UiContext);
	const [files, setFiles] = useState<(File | ListFile)[]>(
		bid.documents.map((doc) => new File([''], doc.documentName)).reverse()
	);
	const isDueDateNotPassed = checkDueDate(tender.dueDate);
	const [filesLoading, setFilesLoading] = useState(false);
	const [emptyBidAlert, setEmptyBidAlert] = useState(false);
	const [dueDateAlert, setDueDateAlert] = useState(false);
	const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
	const [disableButtonsOnSubmit, setDisableSubmitButton] = useState(false);

	const { alerts } = state;
	const uploadingAlert = alerts[match.url]?.[0];

	const { register, handleSubmit, setValue } = useForm<UploadDocumentsFormData>({
		defaultValues: {
			bidId: bid.bidId,
			uploadedFiles: createFileList(bid),
		},
	});

	const onUpload = (uploadedFiles: File[]) => {
		const prevBidDocsNames = files.map((file) => file.name);
		const prevBidDocs = files;

		const filesToSave = uploadedFiles.filter((file) => !prevBidDocsNames.includes(file.name) && file.size);
		const emptyFiles = uploadedFiles.filter((file) => !file.size);
		const duplicateFiles = uploadedFiles.filter((file) => prevBidDocsNames.includes(file.name));

		const path = formatPath(
			`tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(bid.bidId)}/upload`
		);
		if (uploadingAlert) {
			clearAlert(path, 'warning');
		}

		if (filesToSave.length) {
			const filesToUpload = convertToListFiles(filesToSave);
			setFiles((prevFiles) => filesToUpload.concat(prevFiles));
			setFilesLoading(true);

			onUploadDocuments(filesToSave, bid)
				.then(() => {
					setFilesLoading(false);
					setFiles((prevFiles) => prevFiles.map((file) => new File([file], file.name)));
					setUploadedFilesToast(filesToSave, setToast, t);
				})
				.catch(() => {
					setFilesLoading(false);
					setFiles(prevBidDocs);
				});
		}

		if (emptyFiles.length) {
			setEmptyFileAlert(emptyFiles, setAlert, t, path);
		}

		if (duplicateFiles.length) {
			setDuplicateFileAlert(duplicateFiles, setAlert, t, path);
		}
	};

	const onRemove = (filesToRemove: File[]) => {
		setFiles((prevFiles) => prevFiles.filter((value) => filesToRemove.every((fileFilter) => fileFilter !== value)));
		setFilesLoading(true);

		onRemoveDocuments(filesToRemove, bid).then(() => {
			setFilesLoading(false);
			setRemovedFilesToast(filesToRemove, setToast, t);
		});
	};

	const handleSubmitRequest = () => {
		if (!isDueDateNotPassed) {
			setDueDateAlert(true);
		} else {
			// eslint-disable-next-line no-unused-expressions
			bid.documents.length ? setShowConfirmSubmit(true) : setEmptyBidAlert(true);
		}
	};

	const onSubmit = () => {
		setDisableSubmitButton(true);
		onConfirmContactInformation(
			bid.contactInformation.contact.fullname,
			bid.contactInformation.contact.emailAddress,
			bid.bidId
		).then(() => {
			onSubmitBid(bid).then(() => {
				const formattedPath = formatPath(
					`tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(
						bid.bidId
					)}/summary-readonly`
				);
				setAlert('success', t('bid-submitted-success-alert'), formattedPath);
				setAlert('info', t('bid-submitted-info-alert'), formattedPath);
				push(formattedPath);
			});
		});
	};

	if (!bid.tenderId) return null;

	return (
		<Loader loading={loading}>
			{showConfirmSubmit && (
				<Modal>
					<div className="flex">
						<div className="flex-col w-8/12">
							<div className="flex-col space-y-5 mb-6">
								<h2>{t('bid-confirm-submit-heading')}</h2>
								<div className="text-medium">{t('bid-confirm-submit-subheading')}</div>
								<div>{t('bid-confirm-submit-text')}</div>
							</div>
							<div className="sm:flex space-y-4 sm:space-x-4 sm:space-y-0 justify-start">
								<Button
									type="submit"
									scheme={disableButtonsOnSubmit ? 'disabled' : 'primary'}
									onClick={onSubmit}
									data-cy="confirm-submit-bid"
									disabled={disableButtonsOnSubmit}
								>
									{t('submit')}
								</Button>
								<Button
									scheme={disableButtonsOnSubmit ? 'disabled' : 'tertiary'}
									disabled={disableButtonsOnSubmit}
									onClick={() => setShowConfirmSubmit(false)}
								>
									{t('cancel')}
								</Button>
							</div>
						</div>
						<CloudIcon className="w-4/12" />
						<div className="flex items-start">
							<CloseButton style={{ padding: 0 }} onClick={() => setShowConfirmSubmit(false)} />
						</div>
					</div>
				</Modal>
			)}
			<div className="flex flex-col">
				{!isIntegration && <TenderDueDate {...tender} />}
				{error && <ErrorAlert text={t('saving-error-alert')} onClick={clearError} />}
				<ul className="space-y-4 my-4 md:flex md:justify-between md:w-lg lg:w-3/5 md:space-y-0" data-loading>
					<li>
						<div className="text-medium">{t('summary-bid-contact')}</div>
						<div>{bid.contactInformation.contact.fullname}</div>
						<div>{bid.contactInformation.contact.emailAddress}</div>
					</li>
					<li>
						<div className="text-medium">{t('summary-bid-company')}</div>
						<div>{bid.contactInformation.company.companyName}</div>
						<div>{bid.contactInformation.company.organizationNumber}</div>
					</li>
				</ul>
				{dueDateAlert && !isDueDateNotPassed && (
					<BiddingAlert
						heading={t('due-date-alert-heading')}
						text={t('due-date-alert-text')}
						onClick={() => setDueDateAlert(false)}
					/>
				)}
				{emptyBidAlert && !files.length && (
					<BiddingAlert
						heading={t('bid-empty-alert-heading')}
						text={t('bid-empty-alert-text')}
						onClick={() => setEmptyBidAlert(false)}
					/>
				)}
				{uploadingAlert && (
					<BiddingAlert
						scheme={uploadingAlert.type}
						heading=""
						text={uploadingAlert.message}
						onClick={() => clearAlert(match.url, uploadingAlert.type)}
					/>
				)}
				<Card className="flex flex-col space-y-4 bg-grey-background my-5" data-loading>
					<div className="text-medium mt-2">{t('bid-documents')}</div>
					{bid.documents.length >= 1 ? (
						<div className="flex flex-no-wrap items-start my-4 relative">
							<CheckmarkFilled24 fill="#25945D" className="mr-2" />
							{bid.documents.length === 1
								? `1 ${t('common:document-uploaded')}`
								: `${bid.documents.length} ${t('common:document-uploaded_plural')}`}
						</div>
					) : (
						<div className="flex flex-no-wrap items-start my-4 relative">
							<Misuse24 className="mr-2" />
							{t('common:no-documents-uploaded')}
						</div>
					)}
				</Card>
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
					<UploadDocumentsForm
						bid={bid}
						files={files}
						register={register}
						setValue={setValue}
						onUpload={onUpload}
						onRemove={onRemove}
					/>
					<Button
						data-cy="submit-bid"
						scheme={bid.documents.length || !filesLoading ? 'primary' : 'disabled'}
						data-loading
						disabled={!bid.documents || filesLoading}
						onClick={handleSubmitRequest}
					>
						{t('submit')}
					</Button>
				</form>
			</div>
		</Loader>
	);
};

export default BidSimpleUpload;
