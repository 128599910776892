import * as React from 'react';
import { FC, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { scrollToTop } from '../../utils';

const ScrollToTop: FC<RouteComponentProps> = ({ history }) => {
	useEffect(() => {
		const unlisten = history.listen(() => {
			scrollToTop();
		});

		return () => {
			unlisten();
		};
	});

	return <></>;
};

export default withRouter(ScrollToTop);
