import '../Bidding/pages/BidEdit/BidEdit.scss';

import { Button } from '@mercell/mercell-ui';
import cx from 'classnames';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import ErrorAlert from '../../../components/alerts/ErrorAlert/ErrorAlert';
import Loader from '../../../components/Loader/Loader';
import { Bid } from '../../../contracts/bidding';
import ApiContext from '../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../providers/RouteProvider/RouteContext';
import UiContext from '../../../providers/UiProvider/UiContext';
import { checkDueDate } from '../../../utils';
import { ListFile } from '../Bidding/components/UploadDocumentsForm/file-uploader/FileList';
import UploadDocumentsForm, {
	UploadDocumentsFormData,
} from '../Bidding/components/UploadDocumentsForm/UploadDocumentsForm';
import {
	convertToListFiles,
	setDuplicateFileAlert,
	setEmptyFileAlert,
	setRemovedFilesToast,
	setUploadedFilesToast,
} from '../Bidding/pages/UploadDocuments/utils';
import TenderDueDate from '../TenderMaterial/components/TenderDueDate/TenderDueDate';
import BiddingAlert from './BiddingAlert';
import { IntegrationProps } from './interfaces';

const createFileList = (bid: Bid) => {
	const formFileList = new DataTransfer();
	const files = bid.documents.map((doc) => new File([''], doc.documentName));

	if (files) {
		files.forEach((file) => {
			formFileList.items.add(file);
		});
	}

	return formFileList.files;
};

const BidSimpleEdit: FC<RouteComponentProps & IntegrationProps> = ({ match, isIntegration }) => {
	const { formatPath } = useContext(RouteContext);
	const { t } = useTranslation(['bidding', 'common']);
	const {
		error,
		clearError,
		isBidModifiedSinceLastSubmit,
		onUploadDocuments,
		onRemoveDocuments,
		onSubmitBid,
		onCancelEditBid,
		bid,
		loading,
		tender,
	} = useContext(ApiContext);
	const { state, setToast, setAlert, clearAlert } = useContext(UiContext);

	const [files, setFiles] = useState<(File | ListFile)[]>(
		bid.documents.map((doc) => new File([''], doc.documentName)).reverse()
	);
	const [filesLoading, setFilesLoading] = useState(false);

	const { alerts } = state;
	const uploadingAlert = alerts[match.url]?.[0];

	const isDueDateNotPassed = checkDueDate(tender.dueDate);

	const { register, setValue, handleSubmit } = useForm<UploadDocumentsFormData>({
		defaultValues: {
			bidId: bid.bidId,
			uploadedFiles: createFileList(bid),
		},
	});

	const [isBidModified, setIsBidModified] = useState(false);

	useEffect(() => {
		const ac = new AbortController();
		const modifiedStatus = isBidModifiedSinceLastSubmit(bid.bidId);
		modifiedStatus.then((res) => setIsBidModified(res));
		return () => ac.abort();
	}, [bid.bidId, isBidModifiedSinceLastSubmit]);

	const { push } = useHistory();
	const [emptyBidAlert, setEmptyBidAlert] = useState(false);
	const [cancelAlert, setCancelAlert] = useState(false);
	const [dueDateAlert, setDueDateAlert] = useState(false);

	const onSubmit = () => {
		onSubmitBid(bid).then(() => {
			const path = formatPath(
				`/tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(bid.bidId)}/summary-readonly`
			);
			setAlert('success', t('bid-updated-success-alert'), path);
			push(path);
		});
	};

	const onUpload = (uploadedFiles: File[]) => {
		const prevBidDocsNames = files.map((file) => file.name);
		const prevBidDocs = files;

		const filesToSave = uploadedFiles.filter((file) => !prevBidDocsNames.includes(file.name) && file.size);
		const emptyFiles = uploadedFiles.filter((file) => !file.size);
		const duplicateFiles = uploadedFiles.filter((file) => prevBidDocsNames.includes(file.name));

		const path = formatPath(
			`tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(bid.bidId)}/edit`
		);

		if (uploadingAlert) {
			clearAlert(path, 'warning');
		}

		if (filesToSave.length) {
			const filesToUpload = convertToListFiles(filesToSave);
			setFiles((prevFiles) => filesToUpload.concat(prevFiles));
			setFilesLoading(true);

			onUploadDocuments(filesToSave, bid)
				.then(() => {
					setFilesLoading(false);
					setFiles((prevFiles) => prevFiles.map((file) => new File([file], file.name)));
					setUploadedFilesToast(filesToSave, setToast, t);
				})
				.catch(() => {
					setFilesLoading(false);
					setFiles(prevBidDocs);
				});
		}

		if (emptyFiles.length) {
			setEmptyFileAlert(emptyFiles, setAlert, t, path);
		}

		if (duplicateFiles.length) {
			setDuplicateFileAlert(duplicateFiles, setAlert, t, path);
		}
	};

	const onRemove = (filesToRemove: File[]) => {
		const remainingFiles = files.filter((value) => filesToRemove.every((fileFilter) => fileFilter !== value));
		setFiles(remainingFiles);

		onRemoveDocuments(filesToRemove, bid).then(() => {
			setFilesLoading(false);
			setRemovedFilesToast(filesToRemove, setToast, t);
		});
	};

	const handleUpdateRequest = () => {
		if (!isDueDateNotPassed) {
			setDueDateAlert(true);
		} else {
			// eslint-disable-next-line no-unused-expressions
			bid.documents.length ? onSubmit() : setEmptyBidAlert(true);
		}
	};

	const cancelEditBid = async () => {
		// Check document list changed?
		await onCancelEditBid(bid);
		await goToSummaryReadOnly();
	};

	const updateBidButtonSchema = !isBidModified || filesLoading || files.length === 0 ? 'disabled' : 'primary';

	const goToSummaryReadOnly = async () => {
		push(
			formatPath(
				`/tender/${encodeURIComponent(bid.tenderId)}/bidding/${encodeURIComponent(bid.bidId)}/summary-readonly`
			)
		);
	};

	return (
		<Loader loading={loading}>
			<div>
				{!isIntegration && <TenderDueDate {...tender} />}
				<h2 className="mt-6 mb-2" data-loading>
					{t('edit-bid-heading')}
				</h2>
				{error && <ErrorAlert text={t('saving-error-alert')} onClick={clearError} />}
				<ul className="space-y-4 my-4 md:flex md:justify-between md:w-lg lg:w-3/5 md:space-y-0" data-loading>
					<li>
						<div className="text-medium">{t('summary-bid-contact')}</div>
						<div>{bid.contactInformation.contact.fullname}</div>
						<div>{bid.contactInformation.contact.emailAddress}</div>
					</li>
					<li>
						<div className="text-medium">{t('summary-bid-company')}</div>
						<div>{bid.contactInformation.company.companyName}</div>
						<div>{bid.contactInformation.company.organizationNumber}</div>
					</li>
				</ul>
				<form onSubmit={handleSubmit(onSubmit)}>
					{cancelAlert && (
						<BiddingAlert
							heading={t('bid-changed-alert-heading')}
							text={t('bid-changed-alert-text')}
							onClick={() => setCancelAlert(false)}
						/>
					)}
					{emptyBidAlert && !files.length && (
						<BiddingAlert
							heading={t('bid-empty-alert-heading')}
							text={t('bid-empty-alert-text')}
							onClick={() => setEmptyBidAlert(false)}
						/>
					)}
					{dueDateAlert && !isDueDateNotPassed && (
						<BiddingAlert
							heading={t('due-date-alert-heading')}
							text={t('due-date-alert-text')}
							onClick={() => setDueDateAlert(false)}
						/>
					)}
					{uploadingAlert && (
						<BiddingAlert
							scheme={uploadingAlert.type}
							heading=""
							text={uploadingAlert.message}
							onClick={() => clearAlert(match.url, uploadingAlert.type)}
						/>
					)}
					<UploadDocumentsForm
						bid={bid}
						setValue={setValue}
						files={files}
						register={register}
						onUpload={onUpload}
						onRemove={onRemove}
					/>
					<div className="mt-6">
						<Button
							scheme={updateBidButtonSchema}
							data-cy="nextStepButton"
							className={cx('my-4 mr-0 md:mr-2', {
								'cursor-not-allowed': filesLoading,
							})}
							data-loading
							style={{ minWidth: '100px' }}
							onClick={handleUpdateRequest}
							disabled={!isBidModified || filesLoading}
						>
							{t('update-bid')}
						</Button>
						<Button
							type="button"
							onClick={() => (isBidModified ? setCancelAlert(true) : cancelEditBid())}
							scheme={filesLoading ? 'disabled' : 'tertiary'}
							data-cy="nextStepButton"
							style={{ minWidth: '100px' }}
							data-loading
							className={filesLoading ? 'cursor-not-allowed' : ''}
							disabled={filesLoading}
						>
							{t('cancel')}
						</Button>
					</div>
				</form>
			</div>
		</Loader>
	);
};

export default BidSimpleEdit;
