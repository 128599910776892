import { MercellUi, NotFoundPage } from '@mercell/mercell-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import AuthorizedRoute from './components/AuthorizedRoute/AuthorizedRoute';
import Layout from './components/Layout/Layout';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ToastProvider from './components/ToastProvider/ToastProvider';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from './i18n';
import NewDefaultTender from './pages/Administration/NewDefaultTender';
import NewTender from './pages/Administration/NewTender';
import BidManagement from './pages/BidManagement/BidManagement';
import Dashboard from './pages/Dashboard/Dashboard';
import Login, { User, user as loadedUser } from './pages/Login/Login';
import Notification from './pages/Notification/Notification';
import RouteContext from './providers/RouteProvider/RouteContext';
import { setApplicationLanguage } from './utils';

interface AppProps {
	user?: User;
	routePrefix?: string;
}

const App: React.FC<AppProps> = ({ user = loadedUser.read() }) => {
	const { formatPath } = useContext(RouteContext);

	setApplicationLanguage('en');
	const { t } = useTranslation('common');

	return (
		<>
			<MercellUi filePath="/client/mercell-ui/">
				<ScrollToTop />
				<Layout>
					<Switch>
						<Route path={formatPath('login')} component={Login} />
						<AuthorizedRoute path={[formatPath('/'), formatPath('/dashboard')]} exact>
							<Dashboard />
						</AuthorizedRoute>
						<AuthorizedRoute path={formatPath('/tender/:tenderId')} component={BidManagement} />
						<AuthorizedRoute path={formatPath(`/notifications`)} component={Notification} />
						<AuthorizedRoute path={formatPath(`/admin/tender/new`)} exact>
							<NewTender />
						</AuthorizedRoute>
						<AuthorizedRoute path={formatPath(`/admin/tender/new-default`)} exact>
							<NewDefaultTender />
						</AuthorizedRoute>
						<Route>
							<NotFoundPage heading={t('not-found-page-heading')} subText={t('not-found-page-text')} />
						</Route>
					</Switch>
				</Layout>
				<ToastProvider />
			</MercellUi>
		</>
	);
};

export default App;
