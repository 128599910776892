import { Toast } from '@mercell/mercell-ui';
import React, { useContext } from 'react';

import UiContext from '../../providers/UiProvider/UiContext';

export const ToastProviderStandalone = () => {
	const { state, clearToast } = useContext(UiContext);
	const toastState = state.toast;

	let toast = null;
	if (toastState.text) {
		toast = (
			<Toast style={{ position: 'relative', left: '41%' }} isInteractive={false}>
				{toastState.text}
			</Toast>
		);
		setTimeout(() => clearToast(), 3000);
	}

	return <>{toast && <div style={{ width: 'inherit', position: 'absolute' }}>{toast}</div>}</>;
};

const ToastProvider = () => {
	const { state, clearToast } = useContext(UiContext);
	const toastState = state.toast;

	let toast = null;
	if (toastState.text) {
		toast = <Toast isInteractive={false}>{toastState.text}</Toast>;
		setTimeout(() => clearToast(), 3000);
	}

	return <>{toast && <div style={{ maxWidth: '300px' }}>{toast}</div>}</>;
};

export default ToastProvider;
