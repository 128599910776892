import React, { useReducer } from 'react';

import { CLEAR_ALERT, CLEAR_TOAST, SET_ALERT, SHOW_TOAST } from './constants';
import { UiContextValue } from './types';
import UiContext from './UiContext';
import uiReducer from './uiReducer';

const initialState = {
	toast: {
		text: '',
	},
	alerts: {},
};

const UiProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(uiReducer, initialState);

	const setToast = (text: string) => {
		const toastAction = {
			payload: {
				text,
			},
			type: SHOW_TOAST,
		};
		dispatch(toastAction);
	};

	const clearToast = () => {
		const toastAction = {
			type: CLEAR_TOAST,
		};
		dispatch(toastAction);
	};

	const setAlert = (type: string, message: string, path: string) => {
		const alertAction = {
			type: SET_ALERT,
			payload: {
				message,
				type,
				path,
			},
		};
		dispatch(alertAction);
	};

	const clearAlert = (path: string, type: string) => {
		const alertAction = {
			type: CLEAR_ALERT,
			payload: {
				path,
				type,
			},
		};
		dispatch(alertAction);
	};

	const value: UiContextValue = {
		state,
		setToast,
		clearToast,
		setAlert,
		clearAlert,
	};

	return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
};

export default UiProvider;
