import { Logout24, Notification24 } from '@carbon/icons-react';
import cx from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../../../pages/Login/AuthContext';
import ApiContext from '../../../../providers/ApiProvider/ApiContext';

const AppMenu: React.FunctionComponent = (props) => {
	const { pathname } = useLocation();
	const { isLoggedIn } = useAuthContext();
	const { onLogout } = useContext(ApiContext);
	const { t } = useTranslation('common');

	return (
		<nav
			id="app-menu"
			className="fixed bottom-0 left-0 flex items-center space-x-4 bg-white w-full border-t border-grey-dark flex justify-evenly sm:relative sm:align-items-center sm:w-fit-content sm:border-0 z-40 sm:h-full"
		>
			{!isLoggedIn && ['/signup', '/login'].every((path) => pathname !== path) && (
				<>
					<Link to="/signup">
						<p className={cx('btn primary', 'nav-button')}>{t('sign-up')}</p>
					</Link>
					<Link to="/login">
						<p className={cx('nav-button btn tertiary', 'nav-button')}>{t('log-in')}</p>
					</Link>
				</>
			)}
			{isLoggedIn && (
				<div className="flex flex-col">
					<NavLink
						to="/notifications"
						className="nav-link flex"
						activeClassName="active active-underline"
						style={{
							minWidth: '100px',
							justifyContent: 'flex-start',
						}}
					>
						<Notification24 />
						{t('notifications')}
					</NavLink>
					<NavLink
						className="nav-link flex"
						to="/login"
						onClick={onLogout}
						style={{
							minWidth: '100px',
							justifyContent: 'flex-start',
						}}
					>
						<Logout24 />
						{t('logout')}
					</NavLink>
				</div>
			)}
		</nav>
	);
};

export default AppMenu;
