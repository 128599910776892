/* eslint-disable-line */
import './TenderHeader.scss';

import { Download24, IconButton, StatusIndicator } from '@mercell/mercell-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../components/Loader/Loader';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import TenderDueDate from '../TenderDueDate/TenderDueDate';
import { useTenderStatuses } from '../TenderStatus/TenderStatus';

const TenderHeader = () => {
	const { t } = useTranslation('tender_material');
	const { tender, invitation, bidDelivery, loading } = React.useContext(ApiContext);
	const { tenderId, name, buyerName } = tender;

	const status = useTenderStatuses(tender, invitation, bidDelivery);
	if (!status || !tender) return null;
	const { isDelivered, isPendingResult } = status;

	const bidDelivered = isDelivered || isPendingResult;

	const downloadDocuments = async () => {
		const url = `/api/File/tender/${tenderId}/zippedDocuments`;
		const link = document.createElement('a');
		link.download = 'TenderDocuments.zip';
		link.href = url;
		link.click();
		link.remove();
	};

	return (
		<Loader loading={loading}>
			<h1 className="bidmanager-tenderdetails-header" data-loading>
				{name}
			</h1>
			<div className="bidmanager-tenderdetails-subtitle" data-loading>
				{buyerName}
			</div>
			<div className="sm:flex items-center" data-loading>
				<TenderDueDate {...tender} />
				{bidDelivered && (
					<StatusIndicator
						data-cy="bidDeliveredBadge"
						scheme="success"
						className="my-4 ml-2 sm:ml-4 md:my-0 md:ml-4"
					>
						{t('bid-delivered-status')}
					</StatusIndicator>
				)}
				{!bidDelivered && (
					<IconButton
						scheme="horizontal"
						className="download-documents-button"
						Icon={Download24}
						onClick={() => downloadDocuments()}
						data-loading
					>
						{t('download-documents')}
					</IconButton>
				)}
			</div>
		</Loader>
	);
};

export default TenderHeader;
