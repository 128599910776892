import { Alert, Button, Input, TextArea } from '@mercell/mercell-ui';
import Axios from 'axios';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CreateTenderRequest } from '../../contracts/administration';
import RouteContext from '../../providers/RouteProvider/RouteContext';

const NewTender: React.FC = () => {
	const { formatPath } = useContext(RouteContext);
	const history = useHistory();
	const [error, setError] = useState();
	const { t } = useTranslation('tender');
	const { register, handleSubmit, errors } = useForm<CreateTenderRequest>();

	const today = new Date(new Date().setDate(new Date().getDate()));
	const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
	const tomorrowStr = `${tomorrow.toISOString().slice(0, -1)}0000Z`;
	const todayStr = `${today.toISOString().slice(0, -1)}0000Z`;

	const onSubmit = async (input: CreateTenderRequest) => {
		setError(undefined);
		try {
			await Axios.post(`/api/admin/tenders/new`, input);
			history.push(formatPath('/dashboard'));
		} catch (e) {
			setError(e.message);
		}
	};

	return (
		<div className="flex justify-center">
			<main className="content content-spacing space-y-6">
				<h1>{t('new-tender-header')}</h1>
				{error && <Alert scheme="danger">{error}</Alert>}
				<form onSubmit={handleSubmit(onSubmit)}>
					<h2 className="my-1">{t('base-information-subheader')}</h2>
					<Input
						id="name"
						name="name"
						label={t('tender-name-label')}
						type="input"
						ref={register({ required: true })}
						errorMessage={errors.name && t('tender-name-error')}
					/>
					<Input
						id="buyerName"
						name="buyerName"
						label={t('buyer-name-label')}
						type="input"
						defaultValue="Mercell"
						ref={register({ required: true })}
						errorMessage={errors.buyerName && t('buyer-name-error')}
					/>
					<TextArea
						id="description"
						name="description"
						label={t('description-label')}
						rows={3}
						defaultValue="Lorem ipsum.."
						ref={register({ required: true })}
						errorMessage={errors.description && t('description-error')}
					/>
					<Input
						id="dueDate"
						name="dueDate"
						label={t('due-date-label')}
						type="input"
						defaultValue={tomorrowStr}
						ref={register({ required: true })}
						errorMessage={errors.dueDate && t('due-date-error')}
					/>
					<h2 className="my-1">{t('contact-subheader')}</h2>
					<Input
						id="contact.firstName"
						name="contact.firstName"
						label={t('firstname-label')}
						type="input"
						ref={register({ required: true })}
						errorMessage={errors.contact?.firstName && t('firstname-error')}
					/>
					<Input
						id="contact.lastName"
						name="contact.lastName"
						label={t('lastname-label')}
						type="input"
						ref={register({ required: true })}
						errorMessage={errors.contact?.lastName && t('lastname-error')}
					/>
					<Input
						id="contact.emailAddress"
						name="contact.emailAddress"
						label={t('email-label')}
						type="input"
						ref={register({ required: true })}
						errorMessage={errors.contact?.emailAddress && t('email-error')}
					/>
					<h2 className="my-1">{t('settings-subheader')}</h2>
					<Input
						id="publicationDate"
						name="publicationDate"
						label={t('publication-date-label')}
						type="input"
						defaultValue={todayStr}
						ref={register({ required: true })}
						errorMessage={errors.publicationDate && t('publication-date-error')}
					/>
					<Input
						id="durationInDays"
						name="durationInDays"
						label={t('duration-in-days-label')}
						defaultValue="30"
						type="input"
						ref={register()}
					/>
					<Input
						id="questionsDueDate"
						name="questionsDueDate"
						label={t('questions-due-date-label')}
						type="input"
						defaultValue={tomorrowStr}
						ref={register({ required: true })}
						errorMessage={errors.questionsDueDate && t('questions-due-date-error')}
					/>
					<Input
						id="bidValidUntilDate"
						name="bidValidUntilDate"
						label={t('bids-valid-until-label')}
						type="input"
						defaultValue={tomorrowStr}
						ref={register({ required: true })}
					/>
					<Input id="mainArea" name="mainArea" label={t('main-area-label')} type="input" ref={register()} />
					<Input
						id="caseNumber"
						name="caseNumber"
						label={t('case-number-label')}
						type="input"
						ref={register()}
					/>
					<Input
						id="evaluationMethod"
						name="evaluationMethod"
						label={t('evaluation-method-label')}
						type="input"
						ref={register()}
					/>
					<Input
						id="contractType"
						name="contractType"
						label={t('contract-type-label')}
						type="input"
						ref={register()}
					/>
					<Input id="procedure" name="procedure" label={t('procedure-label')} type="input" ref={register()} />
					<Input
						id="awardCriteria"
						name="awardCriteria"
						label={t('award-criteria-label')}
						type="input"
						ref={register()}
					/>
					<Button type="submit" scheme="primary">
						{t('submit')}
					</Button>
				</form>
			</main>
		</div>
	);
};

export default NewTender;
