import { StatusIndicator } from '@mercell/mercell-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BidDelivery } from '../../../../../contracts/bidding';
import { Invitation } from '../../../../../contracts/dashboard';
import { Tender } from '../../../../../contracts/tender';
import {
	getIsAwaitingBid,
	getIsBidWithdrawn,
	getIsDelivered,
	getIsInvitationAccepted,
	getIsInvitationDeclined,
	getIsOverdue,
	getIsOverdueInvitationAccepted,
	getIsPendingInvitation,
	getIsPendingResult,
} from '../../selectors';

export const useTenderStatuses = (tender?: Tender, invitation?: Invitation, bidDelivery?: BidDelivery) =>
	React.useMemo(() => {
		if (!tender || !invitation) return null;
		return {
			isInvitationAccepted: getIsInvitationAccepted(invitation),
			isPendingInvitation: getIsPendingInvitation(tender, invitation),
			isOverDue: getIsOverdue(tender, invitation, bidDelivery),
			isOverDueInvitationAccepted: getIsOverdueInvitationAccepted(tender, invitation, bidDelivery),
			isAwaitingBid: getIsAwaitingBid(tender, invitation, bidDelivery),
			isDelivered: getIsDelivered(tender, invitation, bidDelivery),
			isPendingResult: getIsPendingResult(tender, invitation, bidDelivery),
			isInvitationDeclined: getIsInvitationDeclined(invitation),
			isBidWithdrawn: getIsBidWithdrawn(bidDelivery),
		};
	}, [bidDelivery, tender, invitation]);

const TenderStatus: React.FunctionComponent<Tender> = (tender, invitation) => {
	const { t } = useTranslation('tender-material');
	const status = useTenderStatuses(tender, invitation);
	if (!status) return null;
	const {
		isPendingInvitation,
		isDelivered,
		isAwaitingBid,
		isOverDue,
		isOverDueInvitationAccepted,
		isPendingResult,
		isInvitationDeclined,
		isBidWithdrawn,
	} = status;
	return (
		<>
			{isPendingInvitation && <StatusIndicator scheme="waiting">Pending invitation</StatusIndicator>}
			{isDelivered && !isBidWithdrawn && (
				<StatusIndicator data-cy="bidDeliveredBadge" scheme="success">
					{t('bid-delivered-status')}
				</StatusIndicator>
			)}
			{isAwaitingBid && <StatusIndicator scheme="waiting">Awaiting delivery</StatusIndicator>}
			{isPendingResult && !isBidWithdrawn && (
				<StatusIndicator data-cy="bidDeliveredBadge" scheme="waiting">
					{t('awaiting-result-status')}
				</StatusIndicator>
			)}
			{isOverDue && (
				<StatusIndicator data-cy="dueDatePassedBadge" scheme="danger">
					{t('due-date-passed-status')}
				</StatusIndicator>
			)}
			{isOverDueInvitationAccepted && (
				<StatusIndicator data-cy="dueDatePassedTopBadge" scheme="danger">
					{t('due-date-passed-status')}
				</StatusIndicator>
			)}
			{isInvitationDeclined && <StatusIndicator scheme="disabled">Invitation declined</StatusIndicator>}
		</>
	);
};

export default TenderStatus;
