import { Alert, NewPlatform } from '@mercell/mercell-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ApiErrorResolver from '../../components/ApiErrorResolver/ApiErrorResolver';
import Loader from '../../components/Loader/Loader';
import ApiContext from '../../providers/ApiProvider/ApiContext';
import OngoingProcessCard from './components/OngoingProcessCard/OngoingProcessCard';

const Dashboard: React.FunctionComponent = (props) => {
	const { onOngoingProcesses, ongoings, loading } = React.useContext(ApiContext);
	const [updated, setUpdated] = React.useState(false);
	const { t } = useTranslation('dashboard');

	React.useLayoutEffect(() => {
		onOngoingProcesses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updated]);

	const isEmpty = !(ongoings && ongoings.ongoingProcesses.length > 0);

	return (
		<Loader loading={loading}>
			<div className="flex flex-col p-6 md:px-24 lg:flex-row md:justify-center w-full md:mt-20 mx-auto max-w-screen-xl">
				<main className="w-full lg:w-2/3">
					<h1 data-cy="home-title" className="mb-12" data-loading>
						{t('home-header')}
					</h1>
					<div className="mt-12">
						<ApiErrorResolver />
						<h2 data-loading>{t('process-subheader')}</h2>
						{isEmpty && (
							<Alert scheme="info" id="noOngoingProcessesMessage" className="mt-1" data-loading>
								<p className="text-medium">{t('ongoing-process-title-alert')}</p>
								<p>{t('ongoing-process-message-alert')}</p>
							</Alert>
						)}
						{ongoings?.ongoingProcesses.map((item) => (
							<OngoingProcessCard
								key={item.invitation.invitationId}
								ongoingProces={item}
								iHaveChanged={() => setUpdated(true)}
								className="max-w-2xl mt-1"
								data-loading
							/>
						))}
					</div>
				</main>
				<aside className="flex items-start w-full lg:w-1/3 lg:ml-12">
					<div className="lg:translate-y-20" data-loading>
						<NewPlatform
							header={t('new-platform-header')}
							subText={t('new-platform-subtext')}
							linkText={t('new-platform-link-text')}
							email={t('new-platform-email')}
						/>
					</div>
				</aside>
			</div>
		</Loader>
	);
};

export default Dashboard;
