import { TFunction } from 'i18next';

import { ListFile } from '../../components/UploadDocumentsForm/file-uploader/FileList';

const filterFileList = (filter: File[], files: File[]): FileList => {
	const fileList = new DataTransfer();
	const filteredFiles = files.filter((value) => filter.every((fileFilter) => fileFilter !== value));
	filteredFiles.forEach((file) => {
		fileList.items.add(file);
	});
	return fileList.files;
};

export function setUploadedFilesToast(uploadedFiles: File[], setToast: (text: string) => void, t: TFunction) {
	const toastMessage = uploadedFiles.length > 1 ? t('common:file-uploaded_plural') : t('common:file-uploaded');
	setToast(toastMessage);
}

export function setRemovedFilesToast(removedFiles: File[], setToast: (text: string) => void, t: TFunction) {
	const toastMessage = removedFiles.length > 1 ? t('common:file-removed_plural') : t('common:file-removed');
	setToast(toastMessage);
}

export function setEmptyFileAlert(
	emptyFiles: File[],
	setAlert: (type: string, message: string, path: string) => void,
	t: TFunction,
	alertPath: string
) {
	if (emptyFiles.length > 1) {
		setAlert('warning', `${t('common:empty-file-alert_plural')}`, alertPath);
	} else {
		setAlert('warning', `${t('common:empty-file-alert')}: ${emptyFiles[0].name}`, alertPath);
	}
}

export function setDuplicateFileAlert(
	duplicateFiles: File[],
	setAlert: (type: string, message: string, path: string) => void,
	t: TFunction,
	alertPath: string
) {
	if (duplicateFiles.length > 1) {
		setAlert(
			'warning',
			`${t('common:duplicate-file-alert_plural', { files: duplicateFiles.map((f) => f.name) })}`,
			alertPath
		);
	} else {
		setAlert('warning', `${t('common:duplicate-file-alert', { file: duplicateFiles[0].name })}`, alertPath);
	}
}

export function convertToListFiles(files: File[]) {
	return files.map((file) => {
		const newFile: ListFile = new File([file], file.name);
		newFile.isUploading = true;
		return newFile;
	});
}

export default filterFileList;
