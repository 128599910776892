import { Timeline } from '@mercell/mercell-ui';
import * as React from 'react';

import { Tender } from '../../../../../contracts/tender';
import { utcToUsersLocalDate, utcToUsersLocalDateAndTime, utcToUsersLocalTime } from '../../../../../dateTimeUtil';

function getFormattedDate(localDatetime: Date, includeTime = true): string {
	let formattedDate = `${utcToUsersLocalDate(localDatetime)}`;
	const year = `${new Date(formattedDate).getFullYear().toString()}`;
	formattedDate = formattedDate.replace(` ${year}`, '');
	formattedDate = formattedDate.replace(`${year}`, '');
	if (includeTime) {
		formattedDate += ` ${utcToUsersLocalTime(localDatetime)}`;
	}
	return formattedDate;
}

enum TenderDateType {
	PublicationDate,
	QuestionDueDate,
	BidDueDate,
	BidValidUntilDate,
}

const generateEvents = (dates: (string | TenderDateType)[][]) => {
	let events: Array<{
		isoDate: string;
		dateLabel: string;
		description: string;
	}> = [];
	events = dates.map((item) => {
		const event = {
			isoDate: new Date(item[0]).toISOString(),
			dateLabel: getFormattedDate(new Date(item[0])),
			description: 'Publication date',
		};

		switch (item[1]) {
			case TenderDateType.PublicationDate: {
				event.description = 'Publication date';
				return event;
			}
			case TenderDateType.QuestionDueDate: {
				event.description = 'Due date to ask questions';
				return event;
			}
			case TenderDateType.BidDueDate: {
				event.description = 'Bid due date';
				return event;
			}
			case TenderDateType.BidValidUntilDate: {
				event.description = 'Period of validity';
				event.dateLabel = getFormattedDate(new Date(item[0]), false);
				return event;
			}
			default:
				throw Error('Unknown date type');
		}
	});

	return events;
};

export interface TenderTimelineProps {
	tender: Tender;
}

const TenderTimeline: React.FC<TenderTimelineProps> = ({ tender }) => {
	const dates = [
		[utcToUsersLocalDateAndTime(tender.publicationDate), TenderDateType.PublicationDate],
		[utcToUsersLocalDateAndTime(tender.questionsDueDate), TenderDateType.QuestionDueDate],
		[utcToUsersLocalDateAndTime(tender.dueDate), TenderDateType.BidDueDate],
		[utcToUsersLocalDateAndTime(tender.bidValidUntilDate), TenderDateType.BidValidUntilDate],
	];

	return (
		<div>
			<Timeline className="flex-no-wrap" yearHeadlines events={generateEvents(dates)} chronological />
		</div>
	);
};

export default TenderTimeline;
