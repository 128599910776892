import { deleteDocument, uploadDocument } from '../../../../api';
import { Bid, Document as BidDocument } from '../../../../contracts/bidding';
import { AxiosRequest } from '../types';

const useSyncDocuments = () => {
	const uploadDocuments = async (documents: File[], bidId: string) => {
		if (documents.length === 0) {
			return;
		}
		// We want to upload in sequence since the server
		// does not support concurrency at this moment
		const requests: AxiosRequest[] = [];

		documents.forEach((doc) => {
			const bodyFormData = new FormData();
			bodyFormData.append('BidId', bidId);
			bodyFormData.append('UploadedDocument', doc);

			requests.push(() => uploadDocument(bodyFormData));
		});

		await makeRequests(requests);
	};

	const makeRequests = async (requests: AxiosRequest[]) => {
		// eslint-disable-next-line no-restricted-syntax
		for (const req of requests) {
			// eslint-disable-next-line no-await-in-loop
			await req();
		}
	};

	const deleteDocuments = async (documents: BidDocument[]) => {
		if (documents.length === 0) {
			return;
		}
		// We want to upload in sequence since the server
		// does not support concurrency at this moment
		const requests: AxiosRequest[] = [];

		documents.forEach((doc) => {
			requests.push(() => deleteDocument(doc));
		});

		await makeRequests(requests);
	};

	const syncUploadDocuments = async (files: File[], bid: Bid) => {
		const bidDocNames = bid.documents.map((doc) => doc.documentName);
		const docToUploadNames: string[] = files.map((f) => f.name);
		const hasNewDocsToUpload = docToUploadNames.filter((doc) => !bidDocNames.includes(doc)).length > 0;

		if (hasNewDocsToUpload) {
			await uploadDocuments(
				files.filter((f) => f.size > 0),
				bid.bidId
			);
		}
	};

	const syncRemoveDocuments = async (files: File[], bid: Bid) => {
		const docToRemoveNames: string[] = files.map((f) => f.name);
		const docsToRemove = bid.documents.filter((doc) => docToRemoveNames.includes(doc.documentName));

		if (docsToRemove.length > 0) {
			await deleteDocuments(docsToRemove);
		}
	};

	return { syncUploadDocuments, syncRemoveDocuments };
};

export default useSyncDocuments;
