import { useAccordionList } from '@mercell/mercell-ui';
import React, { FC, useContext } from 'react';

import Loader from '../../../../../../components/Loader/Loader';
import ApiContext from '../../../../../../providers/ApiProvider/ApiContext';
import { IQuestion } from '../../../types';
import QuestionAccordion from './QuestionsAccordion/QuestionsAccordion';

interface QuestionsListProps {
	questions: IQuestion[];
	title?: string;
	download?: boolean;
}

const QuestionsList: FC<QuestionsListProps> = ({ questions, title }) => {
	const { expandedElement, value, onClick } = useAccordionList();
	const { loading } = useContext(ApiContext);

	const renderQuestions = () =>
		questions.map((question, index) => {
			const questionTitle = `Q${questions.length - index} - ${question.title}`;
			return (
				<QuestionAccordion
					onClick={onClick}
					expanded={value && (!expandedElement || expandedElement === String(question.id))}
					id={String(question.id)}
					title={questionTitle}
					answer={question.answer}
					question={question.question}
					key={question.id}
					date={question.date}
				/>
			);
		});

	const renderTitle = () => {
		if (!title) return null;
		return (
			<Loader loading={loading}>
				<p className="text-caption italic" data-loading>
					{title}
				</p>
			</Loader>
		);
	};

	return (
		<div className="my-4">
			{renderTitle()}
			{renderQuestions()}
		</div>
	);
};

export default QuestionsList;
