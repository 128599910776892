import React, { FC } from 'react';

import BaseAlert from '../BaseAlert/BaseAlert';

interface SuccessAlertProps {
	text: string;
	onClick: () => void;
}

const SuccessAlert: FC<SuccessAlertProps> = ({ text, onClick }) => (
	<BaseAlert type="success" text={text} onClick={onClick} />
);

export default SuccessAlert;
