import React, { FC, useContext, useLayoutEffect } from 'react';
import { Route, RouteComponentProps, useParams, useRouteMatch } from 'react-router-dom';

import ApiContext from '../../providers/ApiProvider/ApiContext';
import RouteContext from '../../providers/RouteProvider/RouteContext';
// import Bidding from './Bidding/Bidding';
import BidManagementLayout from './Bidding/components/BidManagementLayout/BidManagementLayout';
import AcceptInvitation from './Bidding/pages/AcceptInvitation/AcceptInvitation';
import InvitationDeclined from './Bidding/pages/InvitationDeclined/InvitationDeclined';
import IWillNotDeliver from './Bidding/pages/IWillNotBid/IWillNotBid';
import StartBidProcess from './Bidding/pages/StartBidProcess/StartBidProcess';
import Questions from './Questions/Questions';
import SimpleBiddingRouting from './SimpleBidding/SimpleBiddingRouting';
import TenderMaterial from './TenderMaterial/TenderMaterial';

const BidManagement: FC<RouteComponentProps> = () => {
	const { formatPath } = useContext(RouteContext);
	const match = useRouteMatch();
	const { tenderId } = useParams<{ tenderId: string }>();
	const decodedTenderId = decodeURIComponent(tenderId);
	const { invitation, bid, bidDelivery, communication, fetchBidManagementData, resolved } = useContext(ApiContext);

	useLayoutEffect(() => {
		fetchBidManagementData(decodedTenderId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BidManagementLayout tenderId={decodedTenderId}>
			<Route exact path={formatPath(`${match.url}`)} render={(props) => <TenderMaterial {...props} />} />
			<Route
				path={formatPath(`${match.url}/bidding/invitation-declined/:showConfirmation`)}
				render={(props) => <InvitationDeclined />}
			/>
			<Route
				path={formatPath(`${match.url}/bidding`)}
				render={(props) => {
					if (!resolved) {
						return <AcceptInvitation />;
					}

					// If bid process
					if (!bid) {
						if (!invitation) {
							throw new Error('You are not invited?');
						} else {
							switch (invitation.invitationStatus) {
								case 'Accepted':
									return <StartBidProcess />;

								case 'Declined':
									return <InvitationDeclined />;

								case 'IWillNotDeliver':
									return <IWillNotDeliver />;

								default:
									return <AcceptInvitation />;
							}
						}
					}

					if (!bidDelivery?.deliveryStatus) {
						// eslint-disable-next-line no-console
						console.log('No delivery status');
					}

					// return <Bidding {...props} />;
					return <SimpleBiddingRouting {...props} />;
				}}
			/>

			<Route
				path={formatPath(`${match.url}/communication`)}
				render={(props) => {
					if (!communication) return null;
					return <Questions {...props} />;
				}}
			/>
		</BidManagementLayout>
	);
};

export default BidManagement;
