import './NewQuestion.scss';

import { Alert, Close32 } from '@mercell/mercell-ui';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import ApiErrorResolver from '../../../../../components/ApiErrorResolver/ApiErrorResolver';
import Loader from '../../../../../components/Loader/Loader';
import { PostQuestionRequest } from '../../../../../contracts/communication';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import NewQuestionForm from './NewQuestionForm/NewQuestionForm';

interface NewQuestionProps {
	redirectUrl: string;
	toggleParentAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewQuestion: React.FC<RouteComponentProps & NewQuestionProps> = ({ redirectUrl, toggleParentAlert }) => {
	const [showAlert, toggleAlert] = useState(true);
	const { push } = useHistory();
	const { onNewQuestion, loading } = useContext(ApiContext);
	const { t } = useTranslation('questions');

	const renderAlert = () => {
		if (!showAlert) return null;
		return (
			<Alert scheme="info" className="relative new-question--alert" data-loading>
				<button
					type="button"
					className="absolute top-0 right-0 p-4"
					onClick={() => toggleAlert((prev) => !prev)}
				>
					<Close32 style={{ width: '24px' }} />
				</button>
				<p className="font-bold" style={{ maxWidth: '250px' }}>
					{t('new-question-alert-title')}
				</p>
				<p className="block flex-1 mt-4">{t('new-question-alert-message')}</p>
			</Alert>
		);
	};

	const onSubmit = (data: PostQuestionRequest) => {
		onNewQuestion(data)
			.then(() => {
				toggleParentAlert((prev) => !prev);
				push(redirectUrl);
			})
			.catch(() => console.log('Catching')); // eslint-disable-line no-console
	};

	return (
		<Loader loading={loading}>
			<div className="space-y-4">
				<h2 className="my-4" data-loading>
					{t('post-question-subheader')}
				</h2>
				<ApiErrorResolver />
				<div className="flex md:flex-row bidmanager-newquestion-container">
					<div className="w-full md:w-1/2 mr-8 bidmanager-newquestion-form-container">
						<NewQuestionForm onSubmit={onSubmit} />
					</div>
					<div className="w-full md:w-1/2">{renderAlert()}</div>
				</div>
			</div>
		</Loader>
	);
};

export default NewQuestion;
