import * as React from 'react';
import { useContext, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

// import Bidding from '../../pages/BidManagement/Bidding/Bidding';
import SimpleBiddingRouting from '../../pages/BidManagement/SimpleBidding/SimpleBiddingRouting';
import ApiContext from '../../providers/ApiProvider/ApiContext';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function useGetTenderIdFromPath() {
	const match = useLocation().pathname.match(/\/standalone\/tender\/([^\\/]+)\//);
	if (match != null) {
		return decodeURIComponent(match[1]);
	}

	return null;
}

const StandaloneBidding: React.FC<RouteComponentProps> = (props) => {
	const { fetchBidManagementData } = useContext(ApiContext);
	const query = useQuery();
	const tenderIdFromPath = useGetTenderIdFromPath();

	useEffect(() => {
		const tenderId = query.get('tenderId') || tenderIdFromPath || '123';
		fetchBidManagementData(tenderId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ width: '700px', padding: '2rem' }}>
			{/* <Bidding {...props} /> */}
			<SimpleBiddingRouting {...props} isIntegration />
		</div>
	);
};

export default StandaloneBidding;
