import { Card, Download32, FileList, SimpleAccordion } from '@mercell/mercell-ui';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../components/Loader/Loader';
import { Document } from '../../../../../contracts/tender';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import TenderActions from '../TenderActions/TenderActions';

const TenderBody = () => {
	const { t } = useTranslation('tender_material');
	const { tender, invitation, bidDelivery, loading } = useContext(ApiContext);

	const { documents, description, procedure, evaluationMethod, contractType } = tender;

	let tenderDocuments: File[] = [];
	if (documents) {
		tenderDocuments = documents.map((doc: Document) => new File([''], doc.documentName));
	}

	return (
		<Loader loading={loading}>
			<div className="bidmanager-tenderdetails-description" data-cy="description" data-loading>
				{description}
			</div>
			<Card className="bg-grey-background" data-loading>
				<table className="flex">
					<thead className="inline-block text-medium">
						<tr>
							<th className="my-2 block mr-12">{t('procedure')}</th>
						</tr>
						<tr>
							<th className="my-2 block mr-12">{t('evaluation-method')}</th>
						</tr>
						<tr>
							<th className="my-2 block mr-12">{t('contract-type')}</th>
						</tr>
					</thead>
					<tbody className="inline-block pl-4">
						<tr>
							<td className="my-2 block">{procedure}</td>
						</tr>
						<tr>
							<td className="my-2 block">{evaluationMethod}</td>
						</tr>
						<tr>
							<td className="my-2 block">{contractType}</td>
						</tr>
					</tbody>
				</table>
			</Card>
			<Card data-loading className="bg-grey-background">
				<SimpleAccordion id="card">
					<div className="flex items-center">
						<h3>{t('buyer-documents-subheading')}</h3>
						<div className="ml-10 text-caption">
							{documents.length}
							{documents.length === 1 ? t('document') : t('document_plural')}
						</div>
					</div>
					<FileList
						id="fileList"
						files={tenderDocuments}
						selectAllLabel={t('select-all-text')}
						actions={[
							{
								label: t('download-label'),
								schemeType: 'vertical',
								handler: (fileList) => {
									fileList.forEach((file) => {
										// Find the document id
										const documentId = tender.documents.find(
											(doc: Document) => doc.documentName === file.name
										)?.id;
										const url = `/api/File/tender/download/${encodeURIComponent(documentId || '')}`;
										const link = document.createElement('a');
										link.download = file.name;
										link.href = url;
										link.click();
										link.remove();
									});
								},
								Icon: Download32,
							},
						]}
					/>{' '}
				</SimpleAccordion>
			</Card>
			<TenderActions tender={tender} invitation={invitation} bidDelivery={bidDelivery} />
		</Loader>
	);
};

export default TenderBody;
