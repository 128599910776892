import { NotFoundPage } from '@mercell/mercell-ui';
import { AxiosError } from 'axios';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

interface ErrorComponentProps<T> {
	error: T;
}

export const isAxios = (error: unknown): error is AxiosError => (error as AxiosError)?.isAxiosError;
const isResponseError = (error: unknown): error is Response => !!(error as Response).status && !(error as Response).ok;

const ErrorComponent: React.FunctionComponent<ErrorComponentProps<unknown>> = <T,>({
	error,
}: ErrorComponentProps<T>) => {
	const { t } = useTranslation('common');
	const { pathname } = useLocation();
	const { code } = React.useMemo(() => {
		if (isAxios(error)) return { code: error.response?.status };
		if (isResponseError(error)) return { code: error.status };
		return { code: null };
	}, [error]);
	if (!code) return null;
	if (code === 401) return <Redirect to={`/login?redirect=${pathname}`} />;
	if (code === 403) return <NotFoundPage heading={t('forbidden-page-heading')} subText={t('forbidden-page-text')} />;
	if (code === 404) return <NotFoundPage heading={t('not-found-page-heading')} subText={t('not-found-page-text')} />;
	if (code === 418) return <NotFoundPage heading={t('teapot-page-heading')} subText={t('teapot-page-text"')} />;
	if (code >= 400 && code < 500)
		return <NotFoundPage heading={t('try-again-page-heading')} subText={t('try-again-page-text')} />;
	return <NotFoundPage heading={t('unexpected-error-page-heading')} subText={t('unexpected-error-page-text')} />;
};

export default ErrorComponent;
