import { Button } from '@mercell/mercell-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { ReactComponent as QuestionIcon } from '../../../../../../assets/icons/question.svg';

const NoQuestions = () => {
	const match = useRouteMatch();
	const { t } = useTranslation('questions');

	return (
		<div className="flex flex-col md:flex-col lg:flex-row align-center my-6">
			<div className="mr-12">
				<p className="font-bold">{t('no-questions-title')}</p>
				<p className="my-1">{t('no-questions-text')}</p>
				<Link to={`${match.url}/new-question`}>
					<Button scheme="primary" className="my-8">
						{t('post-new-question')}
					</Button>
				</Link>
			</div>
			<div className="w-1/2">
				<QuestionIcon />
			</div>
		</div>
	);
};

export default NoQuestions;
