import './BidDocumentsList.scss';

import { CaretDown16, CaretUp16, Document24, IconButton } from '@mercell/mercell-ui';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../../../../components/Loader/Loader';
import { Document } from '../../../../../contracts/bidding';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';

export interface BidDocumentListProps {
	documents: Document[];
}

const BidDocumentList: React.FunctionComponent<BidDocumentListProps> = ({ documents }) => {
	const { loading, apiUrl } = useContext(ApiContext);
	const downloadFile = (documentId: string, name: string) => {
		const link = document.createElement('a');
		link.download = name;
		link.href = `${apiUrl}/api/File/download/${encodeURIComponent(documentId)}`;
		link.click();
		link.remove();
	};

	const { t } = useTranslation('common');

	const [showCompressedList, setShowCompressedList] = React.useState(false);

	const documentList = (
		<ul className="bid-document-list list-none" data-loading style={{ marginTop: 0 }}>
			{documents.map((doc, index) => (
				<li key={doc.id} className="mt-3 ">
					<button
						title={doc.documentName}
						type="button"
						className="flex items-center align-middle "
						onClick={() => downloadFile(doc.id, doc.documentName)}
					>
						<Document24 className="mr-2" />
						<p className="align-middle text-primary hover:text-primary-hover hover:underline">
							{doc.documentName}
						</p>
					</button>
					{index !== documents.length - 1 && <hr className="mt-3 border-grey-light" />}
				</li>
			))}
		</ul>
	);

	let documentsToShow;

	if (documents.length < 10) {
		documentsToShow = documentList;
	} else {
		documentsToShow = showCompressedList ? (
			<>
				{documentList}
				<IconButton
					style={{ paddingLeft: 0 }}
					Icon={CaretUp16}
					scheme="horizontal"
					onClick={() => setShowCompressedList(false)}
				>
					{t('hide-documents')}
				</IconButton>
			</>
		) : (
			<IconButton
				style={{ paddingLeft: 0 }}
				Icon={CaretDown16}
				scheme="horizontal"
				onClick={() => setShowCompressedList(true)}
			>
				{t('view-all-documents')}
			</IconButton>
		);
	}

	return <Loader loading={loading}>{documentsToShow}</Loader>;
};

export default BidDocumentList;
