import { format, formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as locals from 'date-fns/locale';

interface LocalSettings {
	timezone: string;
	language: string;
	region?: string;
}

export function getUserProfileSettings(): LocalSettings {
	// TODO: get timezone and language from current user
	// const tz = getBrowserTimeZone();
	// return { timezone: tz, language: 'en', region: 'GB' };
	// return { timezone: 'Europe/Copenhagen', language: 'da' };
	return { timezone: 'Europe/Copenhagen', language: 'en', region: 'GB' };
}

export function datetimeDifference(utcDate: Date | string): string {
	const settings = getUserProfileSettings();

	const diff = new DateTimeBuilder()
		.fromDate(utcDate)
		.toTimezone(settings.timezone)
		.inLanguage(settings.language)
		.atRegion(settings.region)
		.useDiff()
		.build();

	return diff;
}

export function utcToUsersLocalDateAndTime(utcDate: Date | string): string {
	const settings = getUserProfileSettings();

	const formattedDate = new DateTimeBuilder()
		.fromDate(utcDate)
		.toTimezone(settings.timezone)
		.inLanguage(settings.language)
		.atRegion(settings.region)
		.build();

	return formattedDate;
}

export function convertToUtcDateTime(date: Date): Date {
	return new Date(utcToUsersLocalDateAndTime(date));
}
export function utcToUsersLocalDate(utcDate: Date | string): string {
	const settings = getUserProfileSettings();

	const formattedDate = new DateTimeBuilder()
		.fromDate(utcDate)
		.toTimezone(settings.timezone)
		.inLanguage(settings.language)
		.atRegion(settings.region)
		.withDateOnly()
		.build();

	return formattedDate;
}

export function utcToUsersLocalTime(utcDate: Date | string): string {
	const settings = getUserProfileSettings();

	const formattedDate = new DateTimeBuilder()
		.fromDate(utcDate)
		.toTimezone(settings.timezone)
		.inLanguage(settings.language)
		.atRegion(settings.region)
		.withTimeOnly()
		.build();

	return formattedDate;
}

function getLocale(locale: LocalSettings): Locale {
	// code format is ISO 639-1 + optional country code
	const iso6391 = locale.region ? `${locale.language}${locale.region}` : locale.language;

	switch (iso6391) {
		case 'da':
			return locals.da;
		case 'de':
			return locals.de;
		case 'enUS':
			return locals.enUS;
		case 'enGB':
			return locals.enGB;
		default:
			throw Error(`Unsupported language: ${iso6391}`);
	}
}

export function getDate(utc: UtcDateTime): Date {
	// first month of the year in js is 0 (zero indexed) unlike year and date!
	return new Date(Date.UTC(utc.year, utc.month - 1, utc.date, utc.hours, utc.minutes, utc.seconds, utc.ms));
}

export interface UtcDateTime {
	year: number;
	month: number;
	date: number;
	hours: number;
	minutes: number;
	seconds: number;
	ms: number;
}

export class DateTimeBuilder {
	private _utcDate: UtcDateTime;

	private _timeZone?: string;

	private _language?: string;

	private _region?: string;

	private _dateOnly: boolean;

	private _timeOnly: boolean;

	private _format?: string;

	private _diff: boolean;

	constructor() {
		this._utcDate = {
			year: 0,
			month: 0,
			date: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			ms: 0,
		};
		this._dateOnly = false;
		this._timeOnly = false;
		this._diff = false;
	}

	fromDate(date: Date | string): DateTimeBuilder {
		// assert input is a valid utc date/time
		if (date instanceof Date) {
			this._utcDate = parseUtcString(date.toISOString());
		} else {
			this._utcDate = parseUtcString(date);
		}

		return this;
	}

	toTimezone(tz: string): DateTimeBuilder {
		this._timeZone = tz;
		return this;
	}

	inLanguage(lang: string): DateTimeBuilder {
		// aka "local"
		this._language = lang;
		return this;
	}

	atRegion(region?: string): DateTimeBuilder {
		this._region = region;
		return this;
	}

	withDateOnly(): DateTimeBuilder {
		this._dateOnly = true;
		this._timeOnly = false;
		return this;
	}

	withTimeOnly(): DateTimeBuilder {
		this._timeOnly = true;
		this._dateOnly = false;
		return this;
	}

	useFormat(formatStr: string): DateTimeBuilder {
		this._format = formatStr;
		return this;
	}

	useDiff(): DateTimeBuilder {
		this._diff = true;
		return this;
	}

	build(): string {
		if (!this._timeZone) throw Error('No timezone defined!');
		if (!this._language) throw Error('No language defined!');

		const settings: LocalSettings = {
			timezone: this._timeZone,
			language: this._language,
			region: this._region,
		};
		const locale = getLocale(settings);
		const utc = getDate(this._utcDate);

		if (this._diff) {
			// return formatDistanceToNowStrict(utc, { locale, roundingMethod: 'floor' });
			return formatDistanceToNow(utc, { locale });
		}

		const dateInNewTimeZone = utcToZonedTime(utc, this._timeZone);

		// date and time
		let formatStr = 'PPp';

		if (this._format) {
			formatStr = this._format;
		} else if (this._dateOnly) {
			// the default localized date format
			formatStr = 'PP';
		} else if (this._timeOnly) {
			// the default localized time format
			formatStr = 'p';
		}

		return format(dateInNewTimeZone, formatStr, { locale });
	}
}

export function parseUtcString(dateAndTimeInUtc: string): UtcDateTime {
	// Parse a string to JS Date
	// strict format: '2020-12-31T23:58:59.1234567Z'
	const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,7}))?Z$/;

	// DON'T USE: result = new Date('...')
	// Parsing of strings with Date.parse is strongly discouraged due to browser differences and inconsistencies.
	// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date

	const match = dateAndTimeInUtc.match(regex);

	if (match && match.length === 8) {
		const year = parseInt(match[1], 10);
		const month = parseInt(match[2], 10);
		const date = parseInt(match[3], 10);
		const hours = parseInt(match[4], 10);
		const minutes = parseInt(match[5], 10);
		const seconds = parseInt(match[6], 10);
		const fraction = match[7] === undefined ? 0 : parseInt(match[7], 10);
		let ms = 0;

		if (fraction > 0) {
			// recalculate fraction to ms
			const numberOfDigits = match[7].length;
			const fractionOfaSecond = fraction / 10 ** numberOfDigits;
			ms = Math.round(1000 * fractionOfaSecond);

			if (ms === 1000) {
				// if ms is 1000 it should add one second, and it could increase minute, hour, ... all the way up to the year!
				// this is just a rounding issue and doesn't have any significant matters
				ms = 999;
			}
		}

		if (
			year >= 0 &&
			month >= 1 &&
			month <= 12 &&
			date >= 1 &&
			date <= 31 &&
			hours >= 0 &&
			hours <= 23 &&
			minutes >= 0 &&
			minutes <= 59 &&
			seconds >= 0 &&
			seconds <= 59 &&
			ms >= 0 &&
			ms <= 999
		) {
			const result: UtcDateTime = {
				year,
				month,
				date,
				hours,
				minutes,
				seconds,
				ms,
			};
			return result;
		}

		const msg = `year=${year}, month=${month}, date=${date}, hours=${hours} minutes=${minutes}, seconds=${seconds}, ms=${ms}`;
		throw new Error(`Invalid date or time range in UTC string (${msg}): ${dateAndTimeInUtc}`);
	} else {
		throw new Error(`Invalid UTC string format: ${dateAndTimeInUtc}`);
	}
}
