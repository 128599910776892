import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ApiContext from '../../providers/ApiProvider/ApiContext';
import ErrorAlert from '../alerts/ErrorAlert/ErrorAlert';
import { isAxios } from '../ErrorComponent';

const ApiErrorResolver: FC = () => {
	const { t } = useTranslation('common');
	const { error, clearError } = React.useContext(ApiContext);
	const defaultErrorMessage = t('default-error-message');

	const getErrorMessage = () => {
		if (isAxios(error)) {
			switch (error.response?.status) {
				case 500:
					return t('unexpected-error-server-side-message');
				default:
					// unexpected response status code;
					// We need to investigate further
					return defaultErrorMessage;
			}
		}

		return defaultErrorMessage;
	};

	return <>{error && <ErrorAlert text={getErrorMessage()} onClick={clearError} />}</>;
};

export default ApiErrorResolver;
