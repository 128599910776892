import { StatusIndicator } from '@mercell/mercell-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { OngoingProcess } from '../../../../contracts/dashboard';
import * as selectors from '../../selectors';

const OngoingProcessStatus: React.FunctionComponent<OngoingProcess> = (item) => {
	const status = selectors.getOngoingProcessStatus(item);
	const { t } = useTranslation('dashboard');

	return (
		<>
			{status.isPendingInvitation && (
				<StatusIndicator scheme="waiting">{t('pending-invitation-status')}</StatusIndicator>
			)}
			{status.isInvitationDeclined && (
				<StatusIndicator scheme="disabled">{t('invitation-declined-status')}</StatusIndicator>
			)}
			{status.isBidSubmitted && <StatusIndicator scheme="success">{t('bid-submitted-status')}</StatusIndicator>}
			{status.isDueDatePassed && <StatusIndicator scheme="danger">{t('due-date-passed-status')}</StatusIndicator>}
			{status.isWaitingForEvaluation && (
				<StatusIndicator scheme="waiting">{t('waiting-for-evaluation-status')}</StatusIndicator>
			)}
		</>
	);
};

export default OngoingProcessStatus;
