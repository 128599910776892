import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps } from 'react-router-dom';

import SuccessAlert from '../../../components/alerts/SuccessAlert/SuccessAlert';
import Loader from '../../../components/Loader/Loader';
import ApiContext from '../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../providers/RouteProvider/RouteContext';
import NewQuestion from './pages/NewQuestion/NewQuestion';
import QuestionsOverview from './pages/QuestionsOverview/QuestionsOverview';
import { IQuestion } from './types';
import { convertDirectQuestions, convertPublicQuestions } from './utils';

const Question: FC<RouteComponentProps> = ({ match }) => {
	const { formatPath } = useContext(RouteContext);
	const [directQuestionsList, setDirectQuestions] = useState<IQuestion[]>([]);
	const [publicQuestionsList, setPublicQuestions] = useState<IQuestion[]>([]);
	const [showAlert, toggleAlert] = useState(false);
	const { communication, loading } = useContext(ApiContext);
	const { t } = useTranslation('questions');

	useEffect(() => {
		const { directQuestions, publicQuestions } = communication;
		const convertedDirectQuestions = convertDirectQuestions(directQuestions);
		const convertedPublicQuestions = convertPublicQuestions(publicQuestions);

		setDirectQuestions(convertedDirectQuestions);
		setPublicQuestions(convertedPublicQuestions);
	}, [communication]);

	const renderAlert = () => {
		if (!showAlert) return null;
		return <SuccessAlert text={t('success-alert')} onClick={() => toggleAlert((prev) => !prev)} />;
	};

	return (
		<div className="flex flex-col">
			<Loader loading={loading}>
				<h1 data-loading>{t('questions-answers-header')}</h1>
				{renderAlert()}
				<Route
					path={formatPath(`${match.url}/new-question`)}
					render={(props) => (
						<NewQuestion {...props} redirectUrl={match.url} toggleParentAlert={toggleAlert} />
					)}
				/>
				<Route
					exact
					path={formatPath(`${match.url}/`)}
					render={(props) => (
						<QuestionsOverview
							{...props}
							directQuestionsList={directQuestionsList}
							publicQuestionsList={publicQuestionsList}
						/>
					)}
				/>
			</Loader>
		</div>
	);
};

export default Question;
