import { Button } from '@mercell/mercell-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BidProcessIcon } from '../../../../../assets/icons/bid-process.svg';
import ApiErrorResolver from '../../../../../components/ApiErrorResolver/ApiErrorResolver';
import Loader from '../../../../../components/Loader/Loader';
import ApiContext from '../../../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../../../providers/RouteProvider/RouteContext';

const StartBidProcess: React.FunctionComponent = () => {
	const { formatPath } = useContext(RouteContext);
	const { bidDelivery, invitation, onStartDelivery, onResumeBid, loading } = useContext(ApiContext);
	const { push } = useHistory();
	const { t } = useTranslation('bidding');

	const onAccept = async () => {
		await onStartDelivery(decodeURIComponent(invitation.tenderId));
	};

	const onResumeBidding = async () => {
		onResumeBid(encodeURIComponent(bidDelivery.bidId)).then(() => {
			push(formatPath(`/tender/${decodeURIComponent(invitation.tenderId)}/bidding`));
		});
	};

	// const onDecline = async () => {
	// 	onDeclineDelivery(encodeURIComponent(invitation.invitationId)).then(() => {
	// 		push(`/tenders/${invitation.tenderId}/bidding/i-will-not-deliver`);
	// 	});
	// };

	const isBidWithdrawn = bidDelivery !== undefined && bidDelivery.deliveryStatus === 'Withdrawn';

	return (
		<div className="flex-col">
			<Loader loading={loading}>
				<main className="space-y-4 w-lg" style={{ maxWidth: '80%' }}>
					<div>
						<h3 data-loading>{t('start-bid-heading')}</h3>
						<ApiErrorResolver />
						<p data-loading>{t('start-bid-text')}</p>
					</div>
					<div className="sm:flex">
						{isBidWithdrawn && (
							<Button
								scheme="primary"
								onClick={onResumeBidding}
								data-cy="resumeBiddingButton"
								data-loading
								disabled={loading}
							>
								{t('resume-bid')}
							</Button>
						)}
						{!isBidWithdrawn && (
							<Button
								scheme="primary"
								onClick={onAccept}
								data-cy="startDeliveryButton"
								data-loading
								disabled={loading}
							>
								{t('start-bid')}
							</Button>
						)}
						{/* <Button
						className="sm:ml-4 mt-4 sm:mt-0"
						scheme="tertiary"
						data-cy="declineDeliverBid"
						onClick={onDecline}
						className={classnames('sm:ml-4 mt-4 sm:mt-0', { loading })}
					>
						I will not deliver a bid
					</Button> */}
					</div>
				</main>
				<aside className="hidden sm:block translate-x-10">
					<BidProcessIcon className="ml-auto" data-loading />
				</aside>
			</Loader>
		</div>
	);
};

export default StartBidProcess;
