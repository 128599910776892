import { I18nextBrowserLanguageDetector, LOCALE, SanityBackend } from '@mercell/i18n';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(SanityBackend)
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: LOCALE,
		interpolation: {
			escapeValue: false,
		},
		backend: {
			backendOption: {
				sanity: {
					projectId: 'dc6lci5b',
					dataset: 'production',
					useCdn: true,
				},
			},
		},
	});

export default i18n;
