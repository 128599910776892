/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';

import { UiContextValue } from './types';

const initialState: UiContextValue = {
	state: {
		toast: {
			text: '',
		},
		alerts: {},
	},
	setToast: (text: string) => {},
	setAlert: () => {},
	clearToast: () => {},
	clearAlert: () => {},
};

const UiContext = React.createContext(initialState);

export default UiContext;
