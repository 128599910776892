import * as React from 'react';
import { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../pages/Login/AuthContext';
import { Rule } from '../../pages/Login/authorization-rules';
import RouteContext from '../../providers/RouteProvider/RouteContext';

export interface AuthorizationProps {
	rules?: Rule[];
}

const AuthorizedRoute: React.FunctionComponent<RouteProps & AuthorizationProps> = ({
	component,
	children,
	rules,
	...rest
}) => {
	const { formatPath } = useContext(RouteContext);
	const { isLoggedIn, user } = useAuthContext();
	const { pathname } = useLocation();
	const redirect = React.useMemo(() => {
		if (!isLoggedIn) return formatPath(`login?redirect=${pathname}`);
		return rules?.find(({ validator }) => !validator(user))?.redirect;
	}, [isLoggedIn, user, rules, pathname, formatPath]);
	return (
		<Route {...rest}>
			{redirect && <Redirect to={redirect} />}
			{!redirect && (children || (component && React.createElement(component)))}
		</Route>
	);
};

export default AuthorizedRoute;
