import { BidDelivery } from '../../../contracts/bidding';
import { Invitation } from '../../../contracts/dashboard';
import { Tender } from '../../../contracts/tender';

const dueDatePassed = (dueDate: Date) => new Date(dueDate) < new Date();

const invitationAccepted = (invitationStatus: string) =>
	invitationStatus === 'Accepted' || invitationStatus === 'BidDelivered';

const iWillNotDeliver = (invitationStatus: string) => invitationStatus === 'IWillNotDeliver';

const bidDelivered = (invitationStatus: string, deliveryStatus: string | undefined) => {
	if (deliveryStatus === undefined) {
		return false;
	}

	return invitationStatus === 'BidDelivered' && (deliveryStatus === 'Submitted' || deliveryStatus === 'Delivered');
};

const invitationDeclined = (invitationStatus: string) => invitationStatus === 'Declined';

const isBidWithDrawn = (deliveryStatus: string) => deliveryStatus === 'Withdrawn';

export const getIsdueDatePassed = ({ dueDate }: Tender): boolean => dueDatePassed(dueDate);

export const getIsInvitationAccepted = ({ invitationStatus }: Invitation): boolean =>
	invitationAccepted(invitationStatus);

export const getIsInvitationDeclined = ({ invitationStatus }: Invitation): boolean =>
	invitationDeclined(invitationStatus);

export const getIsAwaitingBid = (
	{ dueDate }: Tender,
	{ invitationStatus }: Invitation,
	bidDelivery?: BidDelivery
): boolean =>
	!dueDatePassed(dueDate) &&
	invitationAccepted(invitationStatus) &&
	!bidDelivered(invitationStatus, bidDelivery?.deliveryStatus);

export const getIsPendingInvitation = ({ dueDate }: Tender, { invitationStatus }: Invitation): boolean =>
	!dueDatePassed(dueDate) &&
	!invitationDeclined(invitationStatus) &&
	!invitationAccepted(invitationStatus) &&
	!iWillNotDeliver(invitationStatus);

export const getIsOverdue = (
	{ dueDate }: Tender,
	{ invitationStatus }: Invitation,
	bidDelivery?: BidDelivery
): boolean => dueDatePassed(dueDate) && !bidDelivered(invitationStatus, bidDelivery?.deliveryStatus);

export const getIsOverdueInvitationAccepted = (
	{ dueDate }: Tender,
	{ invitationStatus }: Invitation,
	bidDelivery?: BidDelivery
): boolean =>
	dueDatePassed(dueDate) &&
	invitationAccepted(invitationStatus) &&
	!bidDelivered(invitationStatus, bidDelivery?.deliveryStatus);

export const getIsDelivered = (
	{ dueDate }: Tender,
	{ invitationStatus }: Invitation,
	bidDelivery?: BidDelivery
): boolean => {
	const delivered = bidDelivered(invitationStatus, bidDelivery?.deliveryStatus);
	return delivered && !dueDatePassed(dueDate) && !getIsBidWithdrawn(bidDelivery);
};

export const getIsBidWithdrawn = (bidDelivery: BidDelivery | undefined): boolean => {
	if (bidDelivery === undefined) {
		return false;
	}

	return isBidWithDrawn(bidDelivery.deliveryStatus);
};

export const getIsPendingResult = (
	{ dueDate }: Tender,
	{ invitationStatus }: Invitation,
	bidDelivery?: BidDelivery
): boolean =>
	bidDelivered(invitationStatus, bidDelivery?.deliveryStatus) &&
	dueDatePassed(dueDate) &&
	!getIsBidWithdrawn(bidDelivery);
