import './Header.scss';

import { TopBar } from '@mercell/mercell-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from '../../pages/Login/AuthContext';
import RouteContext from '../../providers/RouteProvider/RouteContext';
import AppMenu from './components/AppMenu/AppMenu';

const Header = () => {
	const { formatPath } = useContext(RouteContext);
	const { user } = useAuthContext();
	const history = useHistory();
	const { t } = useTranslation('common');

	const onLoginClick = () => {
		history.push(formatPath(`/login`));
	};

	const onSignupClick = () => {
		history.push(formatPath(`/`));
	};

	const labels = {
		account: t('account'),
		login: t('log-in'),
		profileMenuClose: t('close-menu'),
		signup: t('sign-up'),
	};

	return (
		<div className="mb-20">
			<TopBar user={user} onLoginClick={onLoginClick} onSignupClick={onSignupClick} labels={labels}>
				<AppMenu />
			</TopBar>
		</div>
	);
};

export default Header;
