import '../Bidding/pages/BidSummaryReadOnly/BidSummaryReadOnly.scss';

import {
	Alert,
	Card,
	CheckmarkOutline24,
	CloseButton,
	Edit24,
	IconButton,
	Information24,
	StatusIndicator,
} from '@mercell/mercell-ui';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import Loader from '../../../components/Loader/Loader';
import { utcToUsersLocalDateAndTime } from '../../../dateTimeUtil';
import ApiContext from '../../../providers/ApiProvider/ApiContext';
import RouteContext from '../../../providers/RouteProvider/RouteContext';
import UiContext from '../../../providers/UiProvider/UiContext';
import { checkDueDate } from '../../../utils';
import BidDocumentList from '../Bidding/components/BidDocumentList/BidDocumentList';
import TenderDueDate from '../TenderMaterial/components/TenderDueDate/TenderDueDate';
import FeedbackLink from './FeedbackLink';
import { IntegrationProps } from './interfaces';

const BidSimpleSummary: React.FunctionComponent<RouteComponentProps & IntegrationProps> = ({
	match,
	isIntegration,
}) => {
	const { formatPath } = useContext(RouteContext);
	const { t } = useTranslation('bidding');
	const { state, clearAlert } = useContext(UiContext);
	const { tender, bid, bidDelivery, onEditBid, loading } = useContext(ApiContext);
	const { alerts } = state;
	const history = useHistory();

	const summaryAlerts = alerts[match.url];

	const clearSummaryAlerts = () => {
		summaryAlerts?.forEach((alert) => clearAlert(match.url, alert.type));
	};

	const lastChangeDate = utcToUsersLocalDateAndTime(bidDelivery.dateSubmitted);

	const showHandleBidButtons = checkDueDate(tender.dueDate);

	const goToEditBid = async () => {
		await onEditBid(bid);
		const tenderId = encodeURIComponent(bid.tenderId);

		const editUrl = formatPath(`/tender/${tenderId}/bidding/${encodeURIComponent(bid.bidId)}/edit`);

		clearSummaryAlerts();
		history.push(editUrl);
	};

	const alertIcon = (alertType: string) => {
		let icon;
		switch (alertType) {
			case 'success':
				icon = <CheckmarkOutline24 />;
				break;
			case 'info':
				icon = <Information24 />;
				break;
			default:
				icon = <Information24 />;
		}
		return icon;
	};

	return (
		<Loader loading={loading}>
			<div className="w-11/12" data-cy="summaryReadonlyContainer">
				<main>
					{!isIntegration && <TenderDueDate {...tender} />}
					{summaryAlerts &&
						summaryAlerts.map((alert) => (
							<Alert
								key={alert.type + alert.message}
								scheme={alert.type}
								className="flex flex-no-wrap items-start my-4 relative"
							>
								{alertIcon(alert.type)}
								<div className="flex-grow w-11/12 ml-2">{alert.message}</div>
								<CloseButton
									className="bidsummary-readonly-close-btn"
									onClick={() => clearAlert(match.url, alert.type)}
								/>
							</Alert>
						))}
					<div className="sm:flex justify-between items-baseline my-6" data-loading>
						<h2 data-cy="bid-submitted-headline">{t('your-bid')}</h2>
						<StatusIndicator scheme="success">
							{t('submitted-status')} {lastChangeDate}
						</StatusIndicator>
					</div>
					<ul className="space-y-4 sm:flex sm:justify-between sm:w-11/12 lg:w-4/6 sm:space-y-0" data-loading>
						<li className="space-y-1">
							<div className="text-medium">{t('summary-bid-contact')}</div>
							<div>{bid.contactInformation.contact.fullname}</div>
							<div>{bid.contactInformation.contact.emailAddress}</div>
						</li>
						<li className="space-y-1">
							<div className="text-medium">{t('summary-bid-company')}</div>
							<div>{bid.contactInformation.company.companyName}</div>
							<div>{bid.contactInformation.company.organizationNumber}</div>
						</li>
					</ul>
					<Card className="flex flex-col space-y-4 bg-grey-background my-5" data-loading>
						<div>
							<div className="text-medium">{t('documents')}</div>
							<div className="flex flex-no-wrap items-start relative">
								{bid.documents.length === 1
									? `1 ${t('document-submitted')}`
									: `${bid.documents.length} ${t('document-submitted_plural')}`}
							</div>
							<BidDocumentList documents={bid.documents} />
						</div>
					</Card>
					{showHandleBidButtons && (
						<div className="sm:flex mt-2" data-loading>
							<IconButton scheme="horizontal" Icon={Edit24} onClick={goToEditBid}>
								{t('edit-bid')}
							</IconButton>
						</div>
					)}
					<FeedbackLink
						question={t('feedback-question')}
						linkText={t('feedback-link-text')}
						url={t('feedback-form-link')}
					/>
				</main>
			</div>
		</Loader>
	);
};

export default BidSimpleSummary;
