import { Alert, Button, Input } from '@mercell/mercell-ui';
import Axios from 'axios';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CreateTenderRequest } from '../../contracts/administration';
import RouteContext from '../../providers/RouteProvider/RouteContext';

const NewDefaultTender: React.FC = () => {
	const { formatPath } = useContext(RouteContext);
	const nouns = ['car', 'house', 'apple', 'banana', 'computer'];
	const adjs = ['big', 'small', 'red', 'blue', 'green'];
	const names = ['Adam', 'Eve', 'John', 'Steve', 'Mary'];
	const techs = ['electronics', 'space', 'environment', 'supply', 'medicin'];

	const r = (a: string[]) => a[Math.floor(Math.random() * a.length)];

	const tenderName = `Buy ${r(adjs)} ${r(nouns)}`;
	const buyerName = `${r(adjs)} ${r(techs)} INC.`.toUpperCase();
	const contactFirstName = `${r(names)}`;

	const history = useHistory();
	const [error, setError] = useState();
	const { t } = useTranslation('tender');
	const { register, handleSubmit, errors } = useForm<CreateTenderRequest>();

	const onSubmit = async (input: CreateTenderRequest) => {
		setError(undefined);

		const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
		const tomorrowStr = `${tomorrow.toISOString().slice(0, -1)}0000Z`;
		const today = `${new Date(new Date().getDate()).toISOString().slice(0, -1)}0000Z`;

		const data: CreateTenderRequest = {
			name: input.name,
			buyerName: input.buyerName,
			contact: input.contact,
			description: 'Lorem ipsum..',
			dueDate: tomorrowStr,
			durationInDays: '30',
			mainArea: 'default main area',
			caseNumber: 'default case number',
			evaluationMethod: 'default evaluation method',
			contractType: 'default contract type',
			procedure: 'default procedure',
			questionsDueDate: tomorrowStr,
			bidValidUntilDate: tomorrowStr,
			awardCriteria: 'default award criteria',
			publicationDate: today,
		};

		try {
			await Axios.post(`/api/admin/tenders/new`, data);
			history.push(formatPath('/dashboard'));
		} catch (e) {
			setError(e);
		}
	};

	return (
		<div className="flex justify-center">
			<main className="content content-spacing space-y-6">
				<h1>{t('new-tender-header')}</h1>
				{error && <Alert scheme="danger">{error}</Alert>}
				<form onSubmit={handleSubmit(onSubmit)}>
					<Input
						id="name"
						name="name"
						label={t('tender-name-label')}
						type="input"
						defaultValue={tenderName}
						ref={register({ required: true })}
						errorMessage={errors.name && t('tender-name-error')}
					/>
					<Input
						id="buyerName"
						name="buyerName"
						label={t('buyer-name-label')}
						type="input"
						defaultValue={buyerName}
						ref={register({ required: true })}
						errorMessage={errors.buyerName && t('buyer-name-error')}
					/>
					<Input
						id="contact.firstName"
						name="contact.firstName"
						label={t('contact-firstname-label')}
						type="input"
						defaultValue={contactFirstName}
						ref={register({ required: true })}
						errorMessage={errors.contact?.firstName && t('contact-firstname-error')}
					/>
					<Alert scheme="info" className="my-2">
						Notes for creating a new tender:
						<ul className="list-disc m-4">
							<li>The tender is only for demo/testing purposes!</li>
							<li>Only you (the bid manager) will be invited to the tender.</li>
							<li>Notification to buyer will be sent to your email.</li>
							<li>We will use your email as registered in bid manager (current user).</li>
							<li>
								We will add a <i>+buyer</i> label in your emails to buyer (eg.
								john.doe+buyer@company.com).
							</li>
						</ul>
					</Alert>
					<Button type="submit" scheme="primary">
						{t('submit')}
					</Button>
				</form>
			</main>
		</div>
	);
};

export default NewDefaultTender;
