import { Alert, CloseButton, Warning24 } from '@mercell/mercell-ui';
import React, { FC } from 'react';

interface ErrorAlertProps {
	heading: string;
	text: string;
	onClick: () => void;
	scheme?: 'warning' | 'success' | 'danger' | 'info';
}

const ErrorAlert: FC<ErrorAlertProps> = ({ heading, text, onClick, scheme = 'warning' }) => (
	<Alert scheme={scheme} className="flex flex-no-wrap items-start my-4 relative">
		<Warning24 />
		<div className="flex-grow w-11/12 ml-2">
			<h3>{heading}</h3>
			{text}
		</div>
		<CloseButton className="close-alert-btn" onClick={onClick} />
	</Alert>
);

export default ErrorAlert;
