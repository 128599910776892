import { MercellUi, NotFoundPage } from '@mercell/mercell-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { setupAxiosInstance } from './api';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import StandaloneBidding from './components/StandaloneBidding/StandaloneBidding';
import { ToastProviderStandalone } from './components/ToastProvider/ToastProvider';
import Login, { User } from './pages/Login/Login';
import ApiContext from './providers/ApiProvider/ApiContext';
import { DEFAULT_API_URL } from './providers/ApiProvider/constants';
import RouteContext from './providers/RouteProvider/RouteContext';
import { setApplicationLanguage } from './utils';

interface AppProps {
	user?: User;
	env: string;
	lang?: string;
}

const AppStandalone: React.FC<AppProps> = ({ env, lang = 'en' }) => {
	const [renderApp, setRenderApp] = useState(false);
	const { formatPath } = useContext(RouteContext);
	const { setAPIUrl, apiUrl } = useContext(ApiContext);

	useEffect(() => {
		setAPIUrl(env);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [env]);

	useEffect(() => {
		setApplicationLanguage(lang);
	}, [lang]);

	useEffect(() => {
		if (apiUrl === DEFAULT_API_URL) return;
		setupAxiosInstance(apiUrl).then(() => {
			setRenderApp((prev) => !prev);
		});
	}, [apiUrl]);

	const { t } = useTranslation('common');

	if (!renderApp) return null;

	return (
		<>
			<MercellUi filePath="/client/mercell-ui/">
				<ScrollToTop />
				<Switch>
					<Route path={formatPath('login')} component={Login} />
					<Route path={formatPath('/')} component={StandaloneBidding} />
					<Route>
						<NotFoundPage heading={t('not-found-page-heading')} subText={t('not-found-page-text')} />
					</Route>
				</Switch>
				<ToastProviderStandalone />
			</MercellUi>
		</>
	);
};

export default AppStandalone;
