import { DirectQuestion, PublicQuestion } from '../../../contracts/communication';
import { IQuestion } from './types';

export const convertDirectQuestions = (directQuestions: DirectQuestion[]) =>
	directQuestions.map((question) => {
		const convertedQuestion: IQuestion = {
			question: question.description,
			questionText: question.replyFromBuyer,
			title: question.title,
			answer: question.replyFromBuyer,
			date: question.sentDate,
			id: question.id,
		};
		return convertedQuestion;
	});

export const convertPublicQuestions = (publicQuestions: PublicQuestion[]) =>
	publicQuestions.map((question, index) => {
		const convertedQuestion: IQuestion = {
			question: question.question,
			questionText: question.questionText,
			title: question.question,
			answer: question.answer,
			date: question.answerDate,
			id: `${question.question}-${index + 1}`,
		};
		return convertedQuestion;
	});
